import { StyleSheet, View, Text, FlatList, Image } from 'react-native'
import CustomButton from "../components/CustomButton";
import RenderHtml from "react-native-render-html";
import { articlesApi } from '../api/articles.api'
import { useInfiniteQuery } from "react-query";
import { useCallback } from 'react'

const BlogItem = ({title, content, image, categories }) => {
    console.log(image)
    return (<View style={styles.blog_item}>
        <View style={{ height: 200, width: '100%'}}>
        <Image source={{ uri: image}} style={{  flex: 1 }}/>
        </View>
        <View  style={styles.content_container}>
         <Text style={styles.title}>{title}</Text>
          <Text numberOfLines={2}> {content.replace(/<[^>]+>/g, '').replace(/\s{2,}/g, ' ').trim() }</Text>
          <View>{categories.map(c => <Text 
          style={{
            width: "fit-content",
            padding:3,
            backgroundColor: "#d4d4d4",
            borderRadius: 5,
            fontSize: 12,
            color: "white",
            fontWeight: "bold",
            margin: 5
          }}>{c.name}</Text>)}
        </View>
        </View>
        <View style={styles.card_footer}>
        <CustomButton size="xs" full text="Детальніше" onPress={() => { }} />
    </View>
    </View>)
}

const BlogsPage = () => {

    const {
         data,
         fetchNextPage,
         isFetchingPreviousPage,
         isFetchingNextPage, }  = useInfiniteQuery(
        ["articles"],
        ({ queryKey, pageParam }) =>
           articlesApi({ ...queryKey[1], page: pageParam }),
          {
          getNextPageParam: (lastPage) => {
            return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
          },
          initialData: [],
          select: (data) => (data?.pages || []).map((page) => page.list).flat(),
        }
      );

    const onEndReached = useCallback(() => {
        if (!isFetchingPreviousPage && !isFetchingNextPage) {
          fetchNextPage();
        }
    }, [isFetchingPreviousPage, isFetchingNextPage]);
    
   return (
    <View style={{ flex: 1}}>
    <FlatList
    data={data}
    onEndReached={onEndReached}
    renderItem={({ item }, index) => {
        return <BlogItem key={item.id} {...item}/>
    }}
    />
    </View>
)
}


const styles = StyleSheet.create({
  blog_item: {
    backgroundColor: "#ffffff",
    width: "97%",
    alignSelf: "center",
    marginVertical: 5,
    borderColor: "#E0E0E0",
    borderWidth: 1,
    borderRadius: 8,
    overflow: "hidden",
  },
  title: {
   color: 'black',
   fontWeight: 700,
   textAlign: 'center',
   marginBottom: 10
},
content_container: { 
    padding: 10
},
   card_footer: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#F9F9F9",
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
})

export default BlogsPage;