import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { SwipeListView } from "react-native-swipe-list-view";
import { useEffect, useState } from "react";
import AvatarImage from "../../components/AvatarImage";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import React from "react";
import { useSelector } from "react-redux";
import { profileSelector } from "../../store/user/user.selector";
import RoundButton from "../../components/RoundButton";
import { useCallback } from "react";
import { useLastMessages } from "../../hooks/index";
import {
  toggleFavorite,
  favoriteIdsApi,
  deleteRoomApi,
} from "../../api/chat.api";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";

const ListEmptyComponent = () => {
  return (
    <View>
      <Text
        style={{
          textAlign: "center",
          fontWeight: "700",
          fontSize: 16,
          marginVertical: 20,
        }}
      >
        Ви ще не маете жодного повідомлення
      </Text>
    </View>
  );
};

const MessageItem = ({ navigation, item, isFavorite, onPress }) => (
  <Pressable
    style={styles.rowFront}
    onPress={() => {
      navigation.push("PrivateChat", { id: item.partnerId });
    }}
  >
    <AvatarImage uri={item.partnerAvatarUrl} />
    <View style={{ flex: 1 }}>
      <Text style={styles.title}>{item?.partnerFullName}</Text>
      <Text style={styles.msg} numberOfLines={2} ellipsizeMode="tail">
        {item.content.text}
      </Text>
    </View>
    <RoundButton
      onPress={() => onPress(item.roomId)}
      iconName={"star-empty"}
      backgroundColor={isFavorite ? "orange" : "white"}
      iconColor={isFavorite ? "white" : "#4F4F4F"}
    />
  </Pressable>
);

const MessagesPage = ({ navigation }) => {
  const [options, setOptions] = useState({ limit: 10000, page: 1 });
  const { id } = useSelector(profileSelector);
  const [favorite, setFavorite] = useState([]);
  const {
    refetch,
    data: messages,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
  } = useLastMessages(options);

  useEffect(() => {
    favoriteIdsApi().then((ids) => {
      setFavorite(ids);
    });
  }, [options]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Menu>
          <MenuTrigger>
            <View style={{ display: "flex", paddingHorizontal: 20 }}>
              <View
                style={{
                  width: 5,
                  height: 5,
                  backgroundColor: "white",
                  margin: 2,
                  borderRadius: 50,
                }}
              />
              <View
                style={{
                  width: 5,
                  height: 5,
                  backgroundColor: "white",
                  margin: 2,
                  borderRadius: 50,
                }}
              />
              <View
                style={{
                  width: 5,
                  height: 5,
                  backgroundColor: "white",
                  margin: 2,
                  borderRadius: 50,
                }}
              />
            </View>
          </MenuTrigger>
          <MenuOptions>
            <MenuOption
              onSelect={() =>
                setOptions((p) => ({ ...p, orderBy: "createdAt" }))
              }
              text="Нещодавні"
            />
            <MenuOption
              onSelect={() =>
                setOptions((p) => ({ ...p, orderBy: "isFavorite" }))
              }
              text="Важливі"
            />
          </MenuOptions>
        </Menu>
      ),
    });
  }, []);

  const [confirmModal, setConfirmModal] = useState({
    visible: false,
    payload: null,
  });
  const [loading, setLoading] = useState(true);

  const onDelete = useCallback((payload) => {
    setConfirmModal({ visible: true, payload });
  }, []);

  const onCancel = useCallback(() => {
    setConfirmModal({ visible: false, payload: {} });
  }, []);

  const onConfirm = useCallback(async () => {
    deleteRoomApi(confirmModal.payload.item.roomId).then(() => {
      setConfirmModal({ visible: false, payload: {} });
      refetch({ refetchPage: (page, index) => index === 0 });
    });
  }, [confirmModal]);

  const onToggle = useCallback(async (id) => {
    const ids = await toggleFavorite(id);
    setFavorite(ids);
  }, []);

  const onEndReached = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);

  return (
    <>
      <SwipeListView
        ListEmptyComponent={loading ? <></> : <ListEmptyComponent />}
        data={messages}
        extraData={messages}
        onEndReached={onEndReached}
        renderItem={({ item }, rowMap) => (
          <MessageItem
            isFavorite={favorite.includes(item.roomId)}
            onPress={(id) => onToggle(id)}
            navigation={navigation}
            item={item}
          />
        )}
        renderHiddenItem={(data, rowMap) => (
          <View style={styles.rowBack}>
            <Pressable
              style={styles.deleteButton}
              onPress={() => onDelete(data)}
            >
              <Text style={styles.deleteButtonText}>Видалити</Text>
            </Pressable>
            <View style={styles.deleteButton}>
              <Text style={styles.deleteButtonText}>Видалити</Text>
            </View>
          </View>
        )}
        leftOpenValue={90}
        rightOpenValue={-90}
      />
      <ConfirmationModal
        visible={confirmModal.visible}
        title={"Ви впевнені, що хочете видалити цей чат?"}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default MessagesPage;

const styles = StyleSheet.create({
  rowBack: {
    width: "100%",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: "600",
  },
  msg: {
    color: "#4F4F4F",
    opacity: 0.8,
  },
  rowFront: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
  },
  deleteButton: {
    backgroundColor: "#EB5757",
    height: 70,
    justifyContent: "center",
    alignItems: "center",
    width: 90,
  },
  deleteButtonText: {
    color: "white",
    fontWeight: "700",
  },
});
