import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { regionsApi } from "../../api/regions.api";

export const fetchRegions = createAsyncThunk(
  "regions/fetchRegions",
  async () => {
    const { data } = await regionsApi();
    return data;
  }
);

const categoriesSlice = createSlice({
  name: "regions",
  initialState: { list: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRegions.pending, (state) => {
      //  state.loading = true
    });
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.list = action.payload;
      //   state.loading = false
    });
    builder.addCase(fetchRegions.rejected, (state) => {
      // state.loading = false
    });
  },
});

export default categoriesSlice.reducer;
