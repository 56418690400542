import { StyleSheet, SafeAreaView, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import _ from "loadsh";
import { useCallback } from "react";
import JobList from "../components/JobList";
import FilterIcon from "../components/FilterIcon";
import SearchFilters from "./SearchFilters";
import { useState } from "react";
import SearchJobsInput from "../components/SearchJobsInput";
import CreateJobButtons from "../components/CreateJobButtons";
import BackButton from "../components/BackButton";
import { useJobSearch } from "../hooks";
export default function Search({ navigation }) {
  const route = useRoute();
  const [showFilters, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    priceType: [],
    taskType: [],
    sortBy: "createdAt",
    sortOrder: "asc",
  });
  const limit = 5;
  const key = decodeURI(route.params?.key || "");

  const onApplyFilters = useCallback((f) => {
    setFilters({ ...f, key, limit, page: 1 });
    setShowFilter(false);
  }, []);

  useEffect(() => {
    setFilters({ ...filters, key, limit, page: 1 });
  }, [key]);

  const {
    data: jobsData,
    fetchNextPage: fetchNextJobsPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching: isJobFetching,
    isRefetching: isJobsRefetching,
  } = useJobSearch(filters);

  const jobs_list = _.get(jobsData, "pages", [])
    .map((page) => page.list)
    .flat();

  const onJobsEndReached = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextJobsPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);

  useEffect(() => {
    navigation.setOptions({
      title: "",
      headerTitleStyle: {
        color: "blue",
        maxWidth: 1000,
      },
      headerBackVisible: false,
      headerLeft: () => <BackButton backLinkTo={"/"} />,
      headerTitle: () => (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignSelf: "flex-start",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <SearchJobsInput />
        </View>
      ),
      headerStyle: {
        backgroundColor: "#209CFF",
        display: "flex",
        justifyContent: "center",
        width: 50,
      },
      headerRight: () => (
        <View style={{ width: 40 }}>
          <FilterIcon
            onPress={() => {
              setShowFilter(true);
            }}
          />
        </View>
      ),
    });
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <SearchFilters
        initialValues={filters}
        visible={showFilters}
        onBack={() => {
          setShowFilter(false);
        }}
        onApplyFilters={onApplyFilters}
      />
      <JobList
        data={jobs_list}
        isFetching={isJobFetching}
        isRefetching={isJobsRefetching}
        onEndReached={onJobsEndReached}
      />
      <CreateJobButtons />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingBottom: 67,
  },
});
