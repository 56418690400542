import React from "react-native";
import { StyleSheet, View } from "react-native";
import SlideButton from "../../../components/SlideButton";
import CustomButton from "../../../components/CustomButton";
import SwiperWrapper from "../../../components/SwiperWrapper";
import ReviewItem from "../../../components/ReviewItem";

const FeedbacksSlider = ({ list, onShowFeedbacks }) => {
  return (
    <View style={styles.review_slider}>
      <SwiperWrapper
        innerContainerStyle={{ height: 257, paddingBottom: 135 }}
        controlsEnabled={true}
        loop={true}
        controlsProps={{
          PrevComponent: ({ onPress }) => (
            <SlideButton
              icon="chevron-left"
              onPress={onPress}
              style={{ bottom: -157, left: 6 }}
            />
          ),
          NextComponent: ({ onPress }) => (
            <SlideButton
              icon="chevron-rightsvg"
              onPress={onPress}
              style={{ bottom: -157, right: 6 }}
            />
          ),
          prevPos: "bottom-left",
          nextPos: "bottom-right",
          dotsPos: false,
        }}
      >
        {list.map((f, i) => (
          <View key={i} style={{ height: "85%" }}>
            <ReviewItem {...f} />
          </View>
        ))}
      </SwiperWrapper>
      <View style={[styles.btn_box, styles.slider_btn]}>
        <CustomButton
          text="Переглянути відгуки"
          size="sm"
          full
          onPress={onShowFeedbacks}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  avatar_img: {
    width: "110%",
    height: "110%",
    resizeMode: "cover",
  },
  check_wrapp: {
    position: "absolute",
    top: 2,
    right: -4,
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: "#6FCF97",
  },
  main_item: {
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    paddingVertical: 18,
  },
  name: {
    fontSize: 18,
    lineHeight: 29,
    color: "#4C4C4C",
    fontWeight: "bold",
    paddingRight: 20,
    marginBottom: 16,
  },
  location: {
    color: "#209CFF",
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "bold",
  },
  gray_text: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
  },
  green_text: {
    fontSize: 18,
    lineHeight: 22,
    color: "#27AE60",
    fontWeight: "bold",
    marginLeft: 5,
  },
  rating_score: {
    fontSize: 26,
    lineHeight: 30,
    color: "#F2994A",
    fontWeight: "bold",
    marginLeft: 5,
  },
  descr_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    fontWeight: "bold",
  },
  descr_text: {
    fontSize: 14,
    lineHeight: 19,
    color: "#4C4C4C",
    marginTop: 20,
  },
  section_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    marginTop: 20,
    paddingBottom: 3,
    fontWeight: "bold",
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    marginBottom: 15,
    marginHorizontal: 5,
  },
  galery: {
    height: 225,
    width: "100%",
  },
  job_slider: {
    minHeight: 206,
    width: "100%",
  },
  review_slider: {
    flex: 1,
    // minHeight: 380,
    width: "100%",
  },
  blue_btn: {
    width: 32,
    height: 40,
    backgroundColor: "#209CFF",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  btn_box: {
    maxWidth: "73%",
    width: "100%",
    alignSelf: "center",
    marginTop: 17,
  },
  slider_btn: {
    position: "absolute",
    alignSelf: "center",
    bottom: 3,
  },
});

export default FeedbacksSlider;
