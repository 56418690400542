import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

export default function CustomButton({
  text,
  type = "blue",
  disabled = false,
  onPress,
  size = "md",
  leftIcon = false,
  children,
  full = false,
}) {
  return (
    <TouchableOpacity
      style={{ flexGrow: 1, alignItems: "center" }}
      onPress={onPress}
      disabled={disabled}
    >
      <View
        style={[
          styles.btn,
          styles[`btn_${type}`],
          styles[`btn_${size}`],
          leftIcon && { flexDirection: "row-reverse" },
          disabled && { backgroundColor: "#fff" },
          full && { maxWidth: "100%", width: "100%" },
        ]}
      >
        <Text
          style={[
            styles.btn_text,
            styles[`btn_text_${type}`],
            type === "white" && styles.btn_text_white,
            type === "default" && styles.btn_text_default,
            type === "cancel" && styles.btn_text_default,
            disabled && styles.btn_text_disabled,
          ]}
        >
          {text}
        </Text>
        {children && <View>{children}</View>}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  btn: {
    width: "auto",
    flexGrow: 1,
    maxWidth: 305,
    height: 48,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: "#209CFF",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#209CFF",
    flexDirection: "row",
    // padding: '5px 13px'
  },
  btn_md: {
    height: 45,
  },
  btn_sm: {
    height: 38,
  },
  btn_xs: {
    height: 26,
  },
  btn_text: {
    lineHeight: 22,
    // fontWeight: 'bold',
    color: "#fff",
  },
  btn_text_md: {
    fontSize: 18,
  },
  btn_text_sm: {
    fontSize: 14,
    lineHeight: 19,
  },
  btn_text_xs: {
    fontSize: 12,
    lineHeight: 12,
  },
  btn_white: {
    backgroundColor: "#fff",
  },
  btn_text_white: {
    color: "#209CFF",
  },
  btn_text_default: {
    color: "#4C4C4C",
  },
  btn_default: {
    // borderColor: "#F2994A",
    backgroundColor: "white",
  },
  btn_cancel: {
    borderColor: "#F2994A",
    backgroundColor: "transparent",
  },
  btn_orange: {
    borderColor: "#F2994A",
    backgroundColor: "#F2994A",
  },
  btn_red: {
    borderColor: "#FF6868",
    backgroundColor: "#FF6868",
  },
  btn_text_disabled: {
    lineHeight: 22,
    fontWeight: "normal",
    color: "#333333",
  },
});
