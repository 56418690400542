import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { jobsApi, jobByAliasApi } from "../../api/jobs.api";

export const fetchJobs = createAsyncThunk("jobs/fetchJobs", async (options) => {
  const { data } = await jobsApi(options);
  return data;
});

export const fetchNextJobs = createAsyncThunk(
  "jobs/fetchNextJobs",
  async (options) => {
    const { data } = await jobsApi(options);
    return data;
  }
);

export const fetchJob = createAsyncThunk("jobs/fetchJob", async (alias) => {
  const { data } = await jobByAliasApi(alias);
  return data;
});

const jobSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: { list: [], count: 0 },
    job: null,
  },
  reducers: {
    resetJobs: (state) => {
      state.jobs = { list: [], count: 0 };
    },
    resetJob: (state) => {
      state.job = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobs.pending, (state) => {
      //  state.loading = true
    });
    builder.addCase(fetchNextJobs.fulfilled, (state, action) => {
      state.jobs.list = [...state.jobs.list, ...action.payload.list];
      state.jobs.page = action.payload.page;
      state.jobs.count = action.payload.count;
      state.jobs.pages = action.payload.pages;
      //   state.loading = false
    });
    builder.addCase(fetchJobs.fulfilled, (state, action) => {
      state.jobs.list = action.payload.list;
      state.jobs.page = action.payload.page;
      state.jobs.count = action.payload.count;
      state.jobs.pages = action.payload.pages;
    });

    builder.addCase(fetchJob.fulfilled, (state, action) => {
      state.job = action.payload;
    });
    builder.addCase(fetchJob.rejected, (state) => {
      // state.loading = false
    });
  },
});

export const { resetJobs } = jobSlice.actions;

export default jobSlice.reducer;
