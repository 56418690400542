import { StyleSheet, View, Text, TextInput } from "react-native";

export default function CustomInput({
  value = "",
  onChangeText,
  onBlur,
  error,
  placeholder,
  label,
  disabled = false,
  multiline = false,
  numberOfLines,
  keyboardType = "default",
  secureTextEntry = false,
  borderColor = "#979797",
  placeholderTextColor = "#333333",
  style,
  ...rest
}) {
  return (
    <View style={styles.form_control}>
      {label && (
        <View>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      {error && (
        <View>
          <Text style={styles.text_error}>{error}</Text>
        </View>
      )}

      <TextInput
        value={String(value)}
        onChangeText={onChangeText}
        onBlur={onBlur}
        disabled={disabled}
        multiline={multiline}
        numberOfLines={numberOfLines}
        style={[
          styles.input,
          { borderColor: error ? "#EB5757" : borderColor },
          style ? style : {},
        ]}
        placeholder={placeholder}
        underlineColorAndroid="transparent"
        placeholderTextColor={placeholderTextColor}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
        {...rest}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  form_control: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "600",
    marginBottom: 10,
    color: "#254D5C",
    marginLeft: 10,
  },
  input: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    lineHeight: 22,
    minHeight: 48,
    borderWidth: 1,
    borderRadius: 30,
  },
  text_error: {
    fontSize: 10,
    lineHeight: 10,
    color: "#EB5757",
    marginLeft: 10,
    marginBottom: 5,
  },
});
