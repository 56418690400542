import React from "react";
import { View, FlatList } from "react-native";
import JobListItem from "../components/JobListItem";
import { useNavigation } from "@react-navigation/native";
import ProfileItemSkeleton from "./Skeletons/ProfileItemSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { toggleFavoriteAction } from "../store/user";
import { profileSelector } from "../store/user/user.selector";

const UserListSkeleton = () => (
  <>
    <ProfileItemSkeleton />
    <ProfileItemSkeleton />
    <ProfileItemSkeleton />
  </>
);

const UserList = ({ list, onEndReached, isFetching, isRefetching }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { favorite, access_token } = useSelector(profileSelector);

  return (
    <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
      <FlatList
        data={list}
        ListHeaderComponent={
          isFetching || isRefetching ? <UserListSkeleton /> : <></>
        }
        renderItem={({ item }) => (
          <JobListItem
            {...item}
            favorite={favorite.includes(item.id)}
            onFavorite={() => {
              if (access_token) {
                dispatch(toggleFavoriteAction(item.id));
              } else {
                navigation.push("SignIn");
              }
            }}
            key={item.id}
            onPress={() => {
              navigation.push("ProfileDetails", { id: item.id });
            }}
          />
        )}
        keyExtractor={(item) => item.id}
        onEndReachedThreshold={0.8}
        onEndReached={onEndReached}
        ListFooterComponent={isFetching ? <UserListSkeleton /> : <></>}
      />
    </View>
  );
};

export default UserList;
