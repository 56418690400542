import { StyleSheet, View, Image, Text } from "react-native";
import SwiperWrapper from "../../../components/SwiperWrapper";
import Icon from "../../../components/icons/CustomIcon";

const GallerySlider = ({ list }) => {
  return (
    <View>
      <Text style={styles.section_title}>Галерея виконаних робіт</Text>
      <View style={styles.galery}>
        <SwiperWrapper
          loop={true}
          controlsEnabled={true}
          controlsProps={{
            PrevComponent: ({ onPress }) => (
              <Icon
                onPress={onPress}
                name="chevron-slide-left"
                color="#FFFFFF"
                size={26}
              />
            ),
            NextComponent: ({ onPress }) => (
              <Icon
                onPress={onPress}
                name="chevron-slide-right"
                color="#FFFFFF"
                size={26}
              />
            ),
            prevPos: "left",
            nextPos: "right",
            dotsPos: false,
          }}
        >
          {list.map((g, i) => (
            <Image
              key={g.fileName}
              source={{ uri: g.fileName }}
              style={{ flex: 1 }}
            />
          ))}
        </SwiperWrapper>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  avatar_img: {
    width: "110%",
    height: "110%",
    resizeMode: "cover",
  },
  check_wrapp: {
    position: "absolute",
    top: 2,
    right: -4,
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: "#6FCF97",
  },
  main_item: {
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    paddingVertical: 18,
  },
  name: {
    fontSize: 18,
    lineHeight: 29,
    color: "#4C4C4C",
    fontWeight: "bold",
    paddingRight: 20,
    marginBottom: 16,
  },
  location: {
    color: "#209CFF",
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "bold",
  },
  gray_text: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
  },
  green_text: {
    fontSize: 18,
    lineHeight: 22,
    color: "#27AE60",
    fontWeight: "bold",
    marginLeft: 5,
  },
  rating_score: {
    fontSize: 26,
    lineHeight: 30,
    color: "#F2994A",
    fontWeight: "bold",
    marginLeft: 5,
  },
  descr_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    fontWeight: "bold",
  },
  descr_text: {
    fontSize: 14,
    lineHeight: 19,
    color: "#4C4C4C",
    marginTop: 20,
  },
  section_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    marginTop: 20,
    paddingBottom: 3,
    fontWeight: "bold",
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    marginBottom: 15,
    marginHorizontal: 5,
  },
  galery: {
    height: 225,
    width: "100%",
  },
  job_slider: {
    minHeight: 206,
    width: "100%",
  },
  review_slider: {
    flex: 1,
    // minHeight: 380,
    width: "100%",
  },
  blue_btn: {
    width: 32,
    height: 40,
    backgroundColor: "#209CFF",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  btn_box: {
    maxWidth: "73%",
    width: "100%",
    alignSelf: "center",
    marginTop: 17,
  },
  slider_btn: {
    position: "absolute",
    alignSelf: "center",
    bottom: 3,
  },
});

export default GallerySlider;
