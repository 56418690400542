import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import DrawerStack from "./DrawerStack";
import { fetchCategories } from "../store/categories";
import { fetchRegions } from "../store/regions";
import LoadingOverLay from "../components/LoadingOverLay";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { profileSelector } from "../store/user/user.selector";
import { getProfileAction, getFavoriteAction } from "../store/user";
import { apiSelector } from "../store/api/selector";
import axios from "axios";

const Stack = createNativeStackNavigator();

const config = {
  screens: {
    Main: {
      screens: {
        ProfileStack: {
          screens: {
            MyProfilePage: "cabinet/profile",
          },
        },
        ChatStack: {
          screens: {
            MessagesPage: "cabinet/chat",
            PrivateChat: "cabinet/chat/private/:id",
          },
        },
        AuthStack: {
          screens: {
            SignIn: "cabinet/auth/sign-in",
            SignUp: "cabinet/auth/sign-up",
            ForgotPassword: "cabinet/auth/reset-password",
          },
        },
        Dashboard: "cabinet/panel",
        Feed: {
          screens: {
            Categories: "/",
            JobsSearch: "jobs/:alias",
            Search: "search",
            BlogsPage: "/articles",
            ForgotPassword: "cabinet/auth/forgot-password",
            ResetPassword: "cabinet/auth/reset-password/:token",
            ProfileDetails: "profile/:id",
            PrivateChat: "profile/:id/chat",
            JobDetails: "jobs/details/:alias",
            FavoritePage: "cabinet/favorite",
            MyJobsPage: "cabinet/task-list",
            CreateJob: "cabinet/create-task",
            EditJob: "cabinet/edit-task/:id",
          },
        },
      },
    },
  },
};

//cabinet/auth/sign-in
const linking = {
  config,
};

export default function RootNavigation() {
  const dispatch = useDispatch();
  const { loading } = useSelector(apiSelector);
  const { access_token } = useSelector(profileSelector);

  useEffect(() => {
    if (access_token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      dispatch(getProfileAction());
      dispatch(getFavoriteAction());
      dispatch(fetchRegions())
      dispatch(fetchCategories())

    }
  }, [access_token]);
  return (
    <>
      {loading ? <LoadingOverLay /> : null}
      <NavigationContainer linking={linking}>
        <Stack.Navigator
          screenOptions={{
            headerStyle: { elevation: 0 },
            cardStyle: { backgroundColor: "#fff" },
          }}
        >
          <Stack.Screen
            name="Main"
            component={DrawerStack}
            options={{ headerShown: false }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
}
