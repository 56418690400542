import React from "react";
import { View, Image, StyleSheet } from "react-native";
import Icon from "./icons/CustomIcon";

const AvatarImage = React.memo(
  ({ uri = "https://freetime.in.ua//images/avatar/3bf927171d.jpeg" }) => (
    <View style={styles.avatar_wrap}>
      {uri ? (
        <Image source={{ uri }} style={styles.avatar_img} />
      ) : (
        <Icon name="user" color="#E0E0E0" size={30} />
      )}
    </View>
  )
);

export default AvatarImage;

const styles = StyleSheet.create({
  avatar: {
    position: "relative",
  },
  avatar_wrap: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderWidth: 2,
    borderColor: "#E0E0E0",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginRight: 10,
  },
  avatar_img: {
    width: "110%",
    height: "110%",
    resizeMode: "cover",
  },
});
