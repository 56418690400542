import { View, Dimensions, FlatList, Text } from "react-native";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import UserListItem from "../components/UserListItem";
import { RefreshControl } from "react-native";
import { apiSelector } from "../store/api/selector";
import { useToggleFavorite, useFavoriteJobs } from "../hooks";
import JobItemSkeleton from "../components/Skeletons/JobItemSkeleton";

const ListEmptyComponent = () => {
  return (
    <View>
      <Text
        style={{
          textAlign: "center",
          fontWeight: "700",
          fontSize: 16,
          marginVertical: 20,
        }}
      >
        Ви ще не маете обраних завдань
      </Text>
    </View>
  );
};

export default function FavoritePage({ navigation }) {
  const { onFavorite, isFavorite } = useToggleFavorite();
  const { loading } = useSelector(apiSelector);
  const {
    data,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching,
    refetch,
  } = useFavoriteJobs({ limit: 8 });

  const onRefresh = useCallback(async () => {}, []);

  const list = (data?.pages || []).map((page) => page.list).flat();

  const { height } = Dimensions.get("window");

  const onEndReached = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);

  return (
    <View style={{ height: height }}>
      <FlatList
        ListEmptyComponent={
          isFetching || isRefetching ? (
            <>
              <JobItemSkeleton />
              <JobItemSkeleton />
              <JobItemSkeleton />
            </>
          ) : (
            <ListEmptyComponent />
          )
        }
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        data={list}
        renderItem={({ item }) => (
          <UserListItem
            key={item.id}
            favorite={isFavorite(item.id)}
            {...item}
            onFavorite={() => onFavorite(item.id)}
            onPress={() => {
              navigation.push("JobDetails", { alias: item.alias });
            }}
          />
        )}
        keyExtractor={(item, index) => item.id}
        onEndReachedThreshold={0.8}
        onEndReached={onEndReached}
        ListFooterComponent={
          isFetching ? (
            <>
              <JobItemSkeleton />
              <JobItemSkeleton />
              <JobItemSkeleton />
            </>
          ) : (
            <></>
          )
        }
      />
    </View>
  );
}
