import axios from "axios";
import { startLoading, endLoading } from "../store/api";

const interceptor = (store) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      store.dispatch(startLoading());
      return config;
    },
    function (error) {
      console.log(error, "heere");
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      store.dispatch(endLoading());
      return response;
    },
    function (error) {
      store.dispatch(endLoading());
      if (error.request.status === 401) {
        // store.dispatch(logOut());
        //  window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
};

export default {
  interceptor,
};
