import Skeleton from "./index";
import { View } from "react-native";

const ProfileSkeleton = () => {
  return (
    <View
      style={{
        borderRadius: 8,
        borderColor: "rgb(224, 224, 224)",
        borderWidth: 1,
        marginBottom: 10,
      }}
    >
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          width={57}
          height={57}
          borderRadius={50}
        />

        <View style={{ flex: 1, justifyContent: "center" }}>
          <Skeleton
            duration={1000}
            colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
            full={"100%"}
            height={20}
          />
          <Skeleton
            duration={1000}
            colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
            full={"70%"}
            height={20}
          />
        </View>
      </View>

      <View style={{ alignItems: "flex-start" }}>
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"90%"}
          height={1}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"50%"}
          height={20}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"90%"}
          height={1}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"50%"}
          height={20}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"96%"}
          height={1}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"40%"}
          height={20}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"96%"}
          height={80}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"96%"}
          height={225}
        />
      </View>
    </View>
  );
};
export default ProfileSkeleton;
