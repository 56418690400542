import { StyleSheet, View, Text, TextInput } from "react-native";
import MaskInput from "react-native-mask-input";

export default function PhoneInput({
  value = "",
  onChangeText,
  onBlur,
  error,
  placeholder,
  label,
  prefix,
  disabled = false,
  multiline = false,
  numberOfLines,
  keyboardType = "default",
  secureTextEntry = false,
  borderColor = "#979797",
  placeholderTextColor = "#333333",
  mask = [],
}) {
  return (
    <View style={styles.form_control}>
      {label && (
        <View>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      {error && (
        <View>
          <Text style={styles.text_error}>{error}</Text>
        </View>
      )}

      <MaskInput
        value={String(value || "")}
        onChangeText={(masked, unmasked) => {
          onChangeText(unmasked.length === 0 ? "+380" : unmasked);
        }}
        mask={[
          "+",
          "3",
          "8",
          "(",
          "0",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        onBlur={onBlur}
        disabled={disabled}
        multiline={multiline}
        numberOfLines={numberOfLines}
        style={[styles.input, { borderColor: error ? "#EB5757" : borderColor }]}
        // placeholder={placeholder}
        underlineColorAndroid="transparent"
        placeholderTextColor={placeholderTextColor}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  form_control: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "600",
    marginBottom: 10,
    color: "#254D5C",
    marginLeft: 10,
  },
  input: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    lineHeight: 22,
    minHeight: 48,
    borderWidth: 1,
    borderRadius: 30,
  },
  text_error: {
    fontSize: 10,
    lineHeight: 10,
    color: "#EB5757",
    marginLeft: 10,
    marginBottom: 5,
  },
});
