import { StyleSheet, View, Text, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";

export default function ReviewItem({
  firstName,
  secondName,
  createdAt,
  average,
  comment,
  ...props
}) {
  return (
    <View style={styles.container}>
      <View style={[styles.flex_row, { justifyContent: "space-between" }]}>
        <Text style={styles.dark_text}>
          {firstName} {secondName}
        </Text>
        <View style={styles.flex_row}>
          <Icon name="calendar" color="#A0A0A0" size={11} />
          <Text style={styles.created_at}>{createdAt.split("T")[0]}</Text>
        </View>
      </View>
      <View
        style={[
          styles.flex_row,
          { justifyContent: "space-between", marginTop: 6 },
        ]}
      >
        <Text style={styles.dark_text}>Добре</Text>
        <View style={[styles.rating, styles.flex_row]}>
          <Icon name="star-full" size={15} color="#F2994A" />
          <Icon name="star-full" size={15} color="#F2994A" />
          <Icon name="star-full" size={15} color="#F2994A" />
          <Icon name="star-full" size={15} color="#F2994A" />
          <Icon name="star-full" size={15} color="#F2994A" />
          <Text style={styles.rating_score}>{average}</Text>
        </View>
      </View>
      <Text style={styles.main_text} numberOfLines={4}>
        {comment}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 8,
    width: "97%",
    alignSelf: "center",
    height: 204,
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  rating_score: {
    fontSize: 18,
    lineHeight: 21,
    color: "#F2994A",
    fontWeight: "bold",
    marginLeft: 5,
  },
  created_at: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
    marginLeft: 10,
  },
  dark_text: {
    fontSize: 18,
    lineHeight: 25,
    color: "#4C4C4C",
    fontWeight: "bold",
  },
  main_text: {
    fontSize: 14,
    lineHeight: 20,
    color: "#4C4C4C",
    marginTop: 17,
    maxHeight: 197,
    overflow: "hidden",
  },
});
