import { Text, Pressable, Image, StyleSheet, View } from "react-native";
import CustomButton from "./CustomButton";
import Icon from "./icons/CustomIcon";
import TimeMoneyIcon from "./icons/TimeMoneyIcon";
import moment from "moment";
import RoundButton from "./RoundButton";

const wasOnline = (date) => {
  moment.locale("uk");
  return moment(new Date(date)).fromNow();
};

const JobListItem = ({
  title,
  description,
  image,
  price,
  avatarUrl,
  onPress,
  taskType,
  offlineAt,
  verification_status,
  feedbacksCount,
  rating,
  onFavorite,
  favorite = false,
}) => (
  <View style={styles.item}>
    <View style={styles.card_header}>
      <View style={styles.header_info}>
        <View style={styles.executor}>
          <Text
            style={[
              styles.gray_text,
              { textDecorationLine: "underline", marginRight: 3 },
            ]}
          >
            Виконавці
          </Text>
          <Icon name="light-bulb" color="#A0A0A0" size={11} />
        </View>
        <View>
          <Text style={styles.gray_text}>
            {" "}
            {offlineAt ? `Був онлайн ${wasOnline(offlineAt)}` : "Онлайн"}
          </Text>
        </View>
      </View>
      <Pressable>
        {/* <RoundButton 
       onPress={onFavorite}
       iconName={"star-empty"}
       backgroundColor={favorite ? "orange" : 'white'} 
       iconColor={favorite ? "white" : "#4F4F4F"}/> */}
      </Pressable>
    </View>

    <View style={styles.card_body}>
      <View style={styles.main}>
        <View style={styles.avatar}>
          <View style={styles.avatar_wrap}>
            {avatarUrl ? (
              <Image source={{ uri: avatarUrl }} style={styles.avatar_img} />
            ) : (
              <Icon name="user" color="#E0E0E0" size={30} />
            )}
          </View>
          {verification_status !== "not_verified" && (
            <View style={styles.check_wrapp}>
              <Icon name="check" color="#fff" size={7} />
            </View>
          )}
        </View>
        <Text style={styles.title}>{title}</Text>
      </View>

      <View style={styles.body_bottom}>
        <View style={styles.flex_row}>
          <Text style={styles.gray_text}>Рейтинг: </Text>
          <View style={styles.flex_row}>
            <Icon name="star-full" size={14} color="#F2994A" />
            <Text style={styles.rating_text}>{rating}</Text>
          </View>
        </View>
        <View style={styles.flex_row}>
          <Text style={styles.gray_text}>Відгуків: </Text>
          <Pressable>
            <Text style={styles.reviews_text}>{feedbacksCount}</Text>
          </Pressable>
        </View>
        <View style={styles.flex_row}>
          <TimeMoneyIcon />
          <Text style={styles.price_text}>{price ? price + ' грн' : 'договірна'}</Text>
        </View>
      </View>
    </View>

    <View style={styles.card_footer}>
      <CustomButton size="xs" full text="Детальніше" onPress={onPress} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  item: {
    backgroundColor: "#ffffff",
    width: "97%",
    alignSelf: "center",
    marginVertical: 5,
    borderColor: "#E0E0E0",
    borderWidth: 1,
    borderRadius: 8,
    overflow: "hidden",
  },
  card_header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    backgroundColor: "#F9F9F9",
  },
  card_body: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  card_footer: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#F9F9F9",
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  header_info: {
    flexDirection: "row",
    alignItems: "center",
  },
  gray_text: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
  },
  executor: {
    marginRight: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  star_box: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    // borderColor: '#4F4F4F',
    // borderWidth: 1,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  main: {
    alignItems: "flex-start",
    flexDirection: "row",
  },
  avatar: {
    position: "relative",
  },
  avatar_wrap: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderWidth: 2,
    borderColor: "#E0E0E0",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  avatar_img: {
    width: "110%",
    height: "110%",
    resizeMode: "cover",
  },
  check_wrapp: {
    position: "absolute",
    top: 2,
    right: -4,
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: "#6FCF97",
  },
  title: {
    fontSize: 14,
    lineHeight: 23,
    color: "#4C4C4C",
    fontWeight: "bold",
    marginLeft: 30,
    paddingTop: 8,
  },
  body_bottom: {
    marginTop: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  price_text: {
    color: "#27AE60",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  rating_text: {
    color: "#F2994A",
    marginLeft: 5,
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 14,
  },
  reviews_text: {
    color: "#209CFF",
    fontSize: 12,
    lineHeight: 14,
    textDecorationLine: "underline",
  },
});

export default JobListItem;
