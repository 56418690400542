import {
  StyleSheet,
  View,
  FlatList,
  SafeAreaView,
  ScrollView,
} from "react-native";
import ShortInfoItem from "../components/ShortInfoItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUserJobs } from "../store/users";
import { userSelector } from "../store/users/users.selector";
import CustomButton from "./../components/CustomButton";

export default function ProfileActualJobs({ navigation, route: { params } }) {
  const dispatch = useDispatch();
  const { jobs } = useSelector(userSelector);
  const jobsList = jobs?.list || [];

  useEffect(() => {
    dispatch(fetchUserJobs({ userId: params.id }));
  }, [params]);

  const FooterButton = () => {
    return (
      <View style={[styles.btn_box]}>
        <CustomButton
          text="Додати завдання"
          size="sm"
          onPress={() => {
            // navigation.push("LeaveFeedback");
          }}
        />
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#ffffff" }}>
      <ScrollView>
        <View style={styles.container}>
          <FlatList
            data={jobsList}
            renderItem={({ item }) => (
              <View style={styles.job}>
                <ShortInfoItem {...item} />
              </View>
            )}
            keyExtractor={(item, i) => i}
            // ListFooterComponent={FooterButton}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingBottom: 50,
    marginTop: 15,
  },
  job: {
    marginBottom: 15,
  },
});
