import { StyleSheet, View, Text, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";
import { useState } from "react";

export default function RatingInput({ label = "", onPress }) {
  const [rating, setRating] = useState(0);
  return (
    <View style={styles.form_control}>
      {label && <Text style={styles.label}>{label}</Text>}

      <View style={styles.stars_row}>
        <Pressable
          onPress={() => {
            setRating(1);
            onPress(1);
          }}
          style={styles.star}
        >
          <Icon
            name="star-full"
            size={25}
            color={rating >= 1 ? "#F2994A" : "grey"}
          />
        </Pressable>

        <Pressable
          onPress={() => {
            setRating(2);
            onPress(2);
          }}
          style={styles.star}
        >
          <Icon
            name="star-full"
            size={25}
            color={rating >= 2 ? "#F2994A" : "grey"}
          />
        </Pressable>

        <Pressable
          onPress={() => {
            setRating(3);
            onPress(3);
          }}
          style={styles.star}
        >
          <Icon
            name="star-full"
            size={25}
            color={rating >= 3 ? "#F2994A" : "grey"}
          />
        </Pressable>

        <Pressable
          onPress={() => {
            setRating(4);
            onPress(4);
          }}
          style={styles.star}
        >
          <Icon
            name="star-full"
            size={25}
            color={rating >= 4 ? "#F2994A" : "grey"}
          />
        </Pressable>

        <Pressable
          onPress={() => {
            setRating(5);
            onPress(5);
          }}
          style={styles.star}
        >
          <Icon
            name="star-full"
            size={25}
            color={rating >= 5 ? "#F2994A" : "grey"}
          />
        </Pressable>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  form_control: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "bold",
    marginBottom: 14,
    color: "#254D5C",
  },
  stars_row: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  star: {
    marginHorizontal: 2,
  },
});
