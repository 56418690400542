import { StyleSheet, View, FlatList, SafeAreaView, Text } from "react-native";
import SearchInput from "../components/SearchInput";
import SelectedListItem from "../components/SelectedListItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegions } from "../store/regions";
import { useEffect, useState } from "react";

export default function RegionsSelect({ selected, onPress }) {
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const regions = useSelector((state) => state.regions.list);

  useEffect(() => {
    dispatch(fetchRegions());
  }, []);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <View style={{ padding: 10 }}>
          <SearchInput
            placeholder="Пошук"
            onChangeText={(k) => {
              setKey(k);
            }}
          />
        </View>
        <FlatList
          data={regions.filter(({ name }) =>
            key ? name.toLowerCase().includes(key.toLowerCase()) : true
          )}
          renderItem={({ item }, i) => (
            <SelectedListItem
              key={item.id}
              selected={selected.includes(item.id)}
              {...item}
              onPress={() => {
                onPress(item.id);
              }}
            />
          )}
          keyExtractor={(item) => item.id}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
});
