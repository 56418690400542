import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import BackButton from "../components/BackButton";
import OpenDrawerButton from "../components/OpenDrawerButton";

const Stack = createNativeStackNavigator();

const commonOptions = {
  headerStyle: { backgroundColor: "#209CFF" },
};

const commonDrawerPageOptions = {
  headerLeft: () => <OpenDrawerButton />,
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

export default function AuthStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignIn"
        component={SignIn}
        options={{ ...commonDrawerPageOptions, title: "" }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{
          ...commonOptions,
          title: "Відновлення паролю",
          headerLeft: () => <BackButton backLinkTo={"/cabinet/auth/sign-in"} />,
        }}
      />
      <Stack.Screen
        name="SignUp"
        component={SignUp}
        options={{ ...commonDrawerPageOptions, title: "Реєстрація" }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{ title: "Новий пароль", headerTintColor: "white" }}
      />
    </Stack.Navigator>
  );
}
