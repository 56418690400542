import {
  View,
  Text,
  FlatList,
  SafeAreaView,
  Pressable,
  StyleSheet,
} from "react-native";
import { useLayoutEffect } from "react";
import { useRoute } from "@react-navigation/native";
import CustomInput from "../../components/CustomInput";
import { useCallback, useRef, useState } from "react";
import { profileSelector } from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import socketio from "socket.io-client";
import { API_URL } from "@env";

const socket = socketio("https://freetime.in.ua", {
  path: "/chat/socket.io",
  autoConnect: false,
});

const wasOnline = (date) => {
  moment.locale("uk");
  return moment(new Date(date)).fromNow();
};

const PartnerMessage = ({ item }) => (
  <View
    style={{ marginBottom: 10, alignItems: "flex-end", flex: 1, width: "100%" }}
    key={item.key}
  >
    <View
      style={{
        backgroundColor: "#DADADA",
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 17,
        marginBottom: 5,
      }}
    >
      <Text style={{ fontSize: 15 }}>{item.partnerId} </Text>

      <Text style={{ fontSize: 15 }}>{item.content.text} </Text>
    </View>
    <Text style={{ color: "#858E99", alignItems: "center", fontSize: 10 }}>
      {moment(item.createdAt).format("hh:mm")} | {wasOnline(item.createdAt)}
    </Text>
  </View>
);

const MyMessage = ({ item }) => (
  <View
    style={{
      marginBottom: 10,
      alignItems: "flex-start",
      flex: 1,
      width: "100%",
    }}
    key={item.key}
  >
    <View
      style={{
        backgroundColor: "#858e99",
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 17,
        marginBottom: 5,
      }}
    >
      <Text style={{ color: "white", fontSize: 15 }}>{item.content.text} </Text>
    </View>
    <Text style={{ color: "#858E99", alignItems: "center", fontSize: 10 }}>
      {moment(item.createdAt).format("hh:mm")} | {wasOnline(item.createdAt)}
    </Text>
  </View>
);

const PrivateChat = ({ navigation }) => {
  const route = useRoute();
  const flatListRef = useRef();
  const intervalId = useRef();
  const { id, access_token } = useSelector(profileSelector);
  const [message, setMessage] = useState("");
  const [height, setHeight] = useState(48);
  const [messages, setMessages] = useState([]);
  const [partner, setPartner] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (messages.length > 0) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index: messages.length - 1,
      });
    }
  }, [messages]);

  useEffect(() => {
    if (access_token) {
      socket.io.opts.query = {
        auth: access_token,
      };
      socket.connect();
    }
  }, [access_token]);

  useEffect(() => {
    socket.emit("joinRoom", { partnerId: route.params.id }, (data) => {
      setMessages(data.messages);
      setPartner(data.partner);
      navigation.setOptions({
        title: `${data.partner.firstName} ${data.partner.secondName}`,
        // title: <AvatarImage />
      });
    });
    return () => {
      socket.close();
    };
  }, []);

  useLayoutEffect(() => {
    socket.on("recieveMessage", (m) => {
      setMessages((prev) => [...prev, m]);
    });

    socket.on("typing", ({ typing }) => {
      setIsTyping(typing);
    });
  }, [route.params, socket, flatListRef]);

  const onChangeText = useCallback((m) => {
    setMessage(m);

    if (intervalId.current) {
      clearTimeout(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      socket.emit("typing", () => {});
    }, 2000);
  }, []);

  const onSendMessage = useCallback(() => {
    setMessage("");
    socket.emit("message", { toId: route.params.id, message }, (m) => {
      setMessages((prev) => [...prev, m]);
    });
  }, [message, socket, route.params, flatListRef]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ paddingHorizontal: 18, paddingTop: 20, flex: 1 }}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={messages}
            contentContainerStyle={{ flexGrow: 1 }}
            ref={(ref) => {
              flatListRef.current = ref;
            }}
            onScrollToIndexFailed={(info) => {
              const wait = new Promise((resolve) => setTimeout(resolve, 500));
              wait.then(() => {
                flatListRef.current?.scrollToIndex({
                  index: info.index,
                  animated: true,
                });
              });
            }}
            renderItem={({ item, index, separators }) =>
              item.fromId === id ? (
                <MyMessage item={item} separators={separators} />
              ) : (
                <PartnerMessage item={item} separators={separators} />
              )
            }
            ListFooterComponentStyle={{ flex: 1, justifyContent: "flex-end" }}
          />
        </View>
        <View
          style={{
            backgroundColor: "white",
            padding: 10,
            borderTopColor: "#E0E0E0",
            borderTopWidth: 1,
            position: "relative",
            paddingBottom: 70,
          }}
        >
          {message.length ? (
            <Pressable
              onPress={onSendMessage}
              style={{
                position: "absolute",
                right: 30,
                top: height / 2,
                zIndex: 1,
              }}
            >
              <Text>Send</Text>
            </Pressable>
          ) : (
            <></>
          )}
          <CustomInput
            placeholder="Ваше повідомлення"
            onChangeText={onChangeText}
            onBlur={() => {}}
            multiline
            style={{ minHeight: height, paddingRight: 50 }}
            value={message}
            onContentSizeChange={(v) => {
              setHeight(v.nativeEvent.contentSize.height);
            }}
          />
          {isTyping && (
            <Text
              style={{ marginVertical: 5 }}
            >{`${partner?.firstName} вводить текст...`}</Text>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};

export default PrivateChat;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
});
