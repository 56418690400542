import RootNavigation from "./navigation/rootNavigation";
import { useFonts } from "expo-font";
import axios from "axios";
import { Provider } from "react-redux";
import "react-native-gesture-handler";
import { store } from "./store";
import { ToastProvider } from "react-native-toast-notifications";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { MenuProvider } from 'react-native-popup-menu';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { API_URL } from "@env"
import { useEffect } from "react";

const queryClient = new QueryClient({   refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,})


export default function App() {
  const [loaded] = useFonts({
    IcoMoon: require("./resources/icons/icomoon.ttf"),
  });

  useEffect(() => {
    axios.defaults.baseURL = `https://freetime.in.ua/api/`;
  },[])


  if (!loaded) {
    return null;
  }

  return (
    <ActionSheetProvider>
    <MenuProvider>
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Provider store={store}>
          <RootNavigation />
        </Provider>
      </ToastProvider>
    </QueryClientProvider>
    </MenuProvider>
    </ActionSheetProvider>
  );
}
