import { Text, Pressable, Image, StyleSheet, View } from "react-native";
import CustomButton from "./CustomButton";
import Icon from "./icons/CustomIcon";

export default function ShortInfoItem({
  avatarUrl,
  image,
  jobDone = false,
  footer = false,
  firstName,
  secondName,
  title,
  price,
  taskType,
  onPress,
}) {
  return (
    <View style={styles.item}>
      <View style={styles.card_header}>
        <View style={styles.header_info}>
          <View style={styles.finder}>
            <Text
              style={[
                styles.gray_text,
                { textDecorationLine: "underline", marginRight: 3 },
              ]}
            >
              {taskType === "find" ? "Шукаю роботу" : "Пропоную роботу"}
            </Text>
            {/* ) : (
              <Text
                style={[styles.gray_text, { color: "#27AE60", marginRight: 3 }]}
              >
                Виконана робота
              </Text>
            )} */}

            <Icon
              name="light-bulb"
              color={jobDone ? "#27AE60" : "#A0A0A0"}
              size={11}
            />
          </View>
          <View style={styles.flex_row}>
            <Icon
              name="calendar"
              color={jobDone ? "#27AE60" : "#A0A0A0"}
              size={11}
            />
            <Text style={[styles.gray_text, jobDone && { color: "#27AE60" }]}>
              13 Липня
            </Text>
          </View>
        </View>
        {!jobDone && (
          <Pressable>
            <View style={styles.star_box}>
              <Icon name="star-empty" color="#4F4F4F" size={14} />
            </View>
          </Pressable>
        )}
      </View>

      <View style={styles.card_body}>
        <View style={styles.main}>
          <Image source={{ uri: image }} style={styles.avatar_img} />
          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text style={styles.title}>{title}</Text>
            <View
              style={[
                styles.flex_row,
                { justifyContent: "space-between", paddingBottom: 5 },
              ]}
            >
              <View style={styles.flex_row}>
                <Icon name="user" color="#A0A0A0" size={11} />
                <Text style={[styles.gray_text, { marginLeft: 3 }]}>
                  {firstName} {secondName}
                </Text>
              </View>
              {!jobDone ? (
                <>
                  <View style={styles.flex_row}>
                    <Icon name="time" color="#A0A0A0" size={15} />
                    <Text style={styles.price_text}>2 дні</Text>
                  </View>
                  <View style={styles.flex_row}>
                    <Icon name="pin" color="#A0A0A0" size={13} />
                    <Text style={styles.price_text}>{price ? price + ' грн' : 'договірна'}</Text>
                  </View>
                </>
              ) : (
                <View style={[styles.rating, styles.flex_row]}>
                  <Icon name="star-full" size={15} color="#F2994A" />
                  <Icon name="star-full" size={15} color="#F2994A" />
                  <Icon name="star-full" size={15} color="#F2994A" />
                  <Icon name="star-full" size={15} color="#F2994A" />
                  <Icon name="star-full" size={15} color="#F2994A" />
                  <Text style={styles.rating_score}>5.0</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>

      {footer && (
        <View style={styles.card_footer}>
          <CustomButton size="xs" text="Відповісти" onPress={onPress} />
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  item: {
    backgroundColor: "#ffffff",
    width: "97%",
    alignSelf: "center",
    borderColor: "#E0E0E0",
    borderWidth: 1,
    borderRadius: 8,
    overflow: "hidden",
    minHeight: 151,
  },
  card_header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    backgroundColor: "#F9F9F9",
    minHeight: 45,
  },
  card_body: {
    paddingRight: 10,
  },
  card_footer: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#F9F9F9",
    paddingVertical: 15,
  },
  header_info: {
    flexDirection: "row",
    alignItems: "center",
  },
  gray_text: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
  },
  finder: {
    marginRight: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  star_box: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#4F4F4F",
    borderWidth: 1,
  },
  main: {
    alignItems: "flex-start",
    flexDirection: "row",
  },
  title: {
    fontSize: 14,
    lineHeight: 23,
    color: "#4C4C4C",
    fontWeight: "bold",
    marginBottom: 20,
    paddingTop: 10,
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  price_text: {
    color: "#27AE60",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  avatar_img: {
    width: 115,
    height: "100%",
  },
  rating_score: {
    fontSize: 18,
    lineHeight: 21,
    color: "#F2994A",
    fontWeight: "bold",
    marginLeft: 5,
  },
});
