import React, {  } from 'react';
import {
  View,
  StyleSheet,
} from 'react-native';
import { DraggableGrid } from 'react-native-draggable-grid';
import Icon from "../icons/CustomIcon";

import { useCallback, useMemo } from "react";
import {
  Image,
} from "react-native";
import { useActionSheet } from "@expo/react-native-action-sheet";
import * as DocumentPicker from "expo-document-picker";


export default function NativeGallery({ mode = "create", images = [], onAdd, onDelete, onOrderChange }) {

  const { showActionSheetWithOptions } = useActionSheet();
 
  const _data = images
  const onPress = (index) => {
    const options = ["Видалити фото", "Повернути фото", "Скасувати"];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex) => {
        switch (selectedIndex) {
          case 1:
            break;

          case destructiveButtonIndex:
            onDelete(index);
            break;

          case cancelButtonIndex:
        }
      }
    );
  };

  const chooseImage = useCallback(
    () => {
      try {
        DocumentPicker.getDocumentAsync({
          // type: [DocumentPicker.types.images],
        }).then((r) => {
          onAdd({ ...r, type: r.mimeType });
        });

        //Setting the state to show multiple file attributes
      } catch (err) {
        //Handling any exception (If any)
        // if (DocumentPicker.isCancel(err)) {
        //   //If user canceled the document selection
        //   alert('Canceled from multiple doc picker');
        // } else {
        //   //For Unknown Error
        //   alert('Unknown Error: ' + JSON.stringify(err));
        //   throw err;
        // }
      }
    },
    [images, mode]
  );

  const render_item = (item,index) =>  {
    return (
      <View
      style={styles.item}
      key={item.key}
    >
      <View style={{ backgroundColor:'#f2f2f2', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
      {item.uri ? <Image style={{  width: '100%', height: '100%'}} source={{ uri: item.uri }} /> :
      <Icon name="camera" color="rgb(32, 156, 255)" size={16} />}

      </View>
      {/* <Text style={styles.item_text}>{item.name}</Text> */}
    </View>
    );
  }

  
  return ( <View style={styles.wrapper}>
    <DraggableGrid
      numColumns={4}
      renderItem={render_item}
      data={_data}
      onItemPress={(props) => {
        console.log([props])
        if(props?.disabledDrag && props?.disabledReSorted) {
          chooseImage()
        } else {
          onPress(props.id) 
        }
      }}
      onDragRelease={(data) => {
        console.log(data)
        onOrderChange(data)
      }}
    />
  </View>
  );
}

const styles = StyleSheet.create({

  wrapper:{
    width:'100%',
    justifyContent:'center',
    paddingBottom: '50%',
    display: 'flex'
  },
  item:{
    width:90,
    height:90,
    borderRadius:3,
    justifyContent:'center',
    alignItems:'center',
    padding: 10
  },
  item_text:{
    fontSize:40,
    color:'#FFFFFF',
  },
});