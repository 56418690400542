import { StyleSheet, View, ActivityIndicator } from "react-native";

export default function LoadingOverLay({ show = false }) {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color="#209CFF" />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff9e",
    position: "absolute",
    zIndex: 11,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
});
