import { StyleSheet, View, Text, Image, ScrollView } from "react-native";
import { useCallback } from "react";
import Icon from "../components/icons/CustomIcon";
import { useDispatch, useSelector } from "react-redux";
import { fetchJob } from "../store/jobs";
import { useEffect } from "react";
import { jobSelector } from "../store/jobs/jobs.selector";
import RenderHtml from "react-native-render-html";
import { useWindowDimensions } from "react-native";
import SwiperWrapper from "../components/SwiperWrapper";
import RoundButton from "../components/RoundButton";
import moment from "moment";
import { profileSelector } from "../store/user/user.selector";
import { useRemoveJob, useToggleFavorite } from "../hooks";

export default function JobDetails({
  route: {
    params: { alias },
  },
}) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { isFavorite, onFavorite } = useToggleFavorite();
  const { id } = useSelector(profileSelector);
  const job = useSelector(jobSelector);
  const deleteMutation = useRemoveJob();
  const favorite = isFavorite(job?.id);

  useEffect(() => {
    dispatch(fetchJob(alias));
  }, []);

  const onDelete = useCallback(async () => {
    deleteMutation.mutate(job.id);
  }, [job]);

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1, backgroundColor: "#ffffff" }}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.title}>{job?.title}</Text>
          <View style={styles.row_between}>
            <View style={styles.row}>
              <Icon name="calendar" size={12} color="#A0A0A0" />
              <Text style={[styles.gray_text, { marginLeft: 5 }]}>
                {moment(job?.createdAt).format("L")}
              </Text>
            </View>
            <View style={styles.row}>
              <Icon name="eye" size={12} color="#A0A0A0" />
              <Text style={[styles.gray_text, { marginLeft: 10 }]}>
                {job?.views} переглядів
              </Text>
            </View>
            <Text style={styles.gray_text}>{job?.favorite} обрано</Text>
            <RoundButton
              onPress={() => onFavorite(job.id)}
              iconName={"star-empty"}
              backgroundColor={favorite ? "orange" : "white"}
              iconColor={favorite ? "white" : "#4F4F4F"}
            />
            {/* { job?.userId === id && <Pressable onPress={onDelete}>
              <View style={styles.remove}>
                <Icon name="trash" size={13} color="#ffffff" />
              </View>
            </Pressable> } */}
          </View>
        </View>

        {job?.gallery && (
          <View style={styles.galery}>
            <SwiperWrapper
              loop={true}
              controlsEnabled={true}
              controlsProps={{
                PrevComponent: ({ onPress }) => (
                  <Icon
                    onPress={onPress}
                    name="chevron-slide-left"
                    color="#FFFFFF"
                    size={26}
                  />
                ),
                NextComponent: ({ onPress }) => (
                  <Icon
                    onPress={onPress}
                    name="chevron-slide-right"
                    color="#FFFFFF"
                    size={26}
                  />
                ),
                prevPos: "left",
                nextPos: "right",
                dotsPos: false,
              }}
            >
              {job.gallery.map((g, i) => (
                <Image
                  key={g.fileName}
                  source={{ uri: g.fileName }}
                  style={{ flex: 1 }}
                />
              ))}
            </SwiperWrapper>
          </View>
        )}

        <Text style={styles.gray_title}>Опис завдання</Text>

        <View style={styles.descr}>
          <RenderHtml
            style={styles.descr}
            contentWidth={width}
            source={{ html: job?.description || "<p></p>" }}
          />
        </View>

        {/* <Text style={styles.gray_title}>Карта завдання</Text>
        <View style={styles.map_box}></View> */}

        <View style={[styles.icon_title, styles.row]}>
          <Text style={[styles.dark_title, { marginRight: 3 }]}>
            Відгуки на вашу пропозицію
          </Text>
          <Icon name="light-bulb" size={13} color="#4C4C4C" />
        </View>
        <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
          {/* <FlatList
            data={jobs}
            renderItem={({ item }) => <JobListItem {...item} onPress={() => { navigation.push('ProfileDetails')}} />}
            keyExtractor={item => item.id}
          /> */}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
  row_between: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    paddingHorizontal: 10,
    marginVertical: 20,
  },
  title: {
    fontSize: 16,
    lineHeight: 26,
    fontWeight: "bold",
    marginBottom: 15,
  },
  gray_text: {
    color: "#A0A0A0",
    fontSize: 12,
    lineHeight: 14,
  },
  remove: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#FF6868",
    justifyContent: "center",
    alignItems: "center",
  },
  galery: {
    height: 185,
    width: "100%",
  },
  gray_title: {
    color: "#828282",
    fontSize: 14,
    lineHeight: 16,
    marginVertical: 12,
    paddingHorizontal: 10,
  },
  descr: {
    color: "#4C4C4C",
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 25,
    paddingHorizontal: 10,
  },
  map_box: {
    marginBottom: 35,
  },
  dark_title: {
    color: "#4C4C4C",
    fontSize: 16,
    lineHeight: 26,
    fontWeight: "bold",
  },
  icon_title: {
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
    marginBottom: 10,
    marginHorizontal: 10,
    paddingBottom: 3,
  },
});
