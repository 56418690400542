import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { usersApi, userByIdApi } from "../../api/users.api";
import { jobsApi } from "../../api/jobs.api";
import { feedbackApi } from "../../api/feedback.api";
import { fetchFeedbacks } from "../feedbacks/index";
import { leaveFeedbackApi } from "../../api/feedback.api";

export const fetchUsers = createAsyncThunk(
  "jobs/fetchUsers",
  async (options) => {
    const { data } = await usersApi(options);
    return data;
  }
);

export const fetchNextUsers = createAsyncThunk(
  "jobs/fetchNextUsers",
  async (options) => {
    const { data } = await usersApi(options);
    return data;
  }
);

export const fetchUser = createAsyncThunk("jobs/fetchUser", async (id) => {
  const { data } = await userByIdApi(id);
  return data;
});

export const leaveUserFeedback = createAsyncThunk(
  "jobs/leaveFeedback",
  async (options, { dispatch }) => {
    await leaveFeedbackApi(options);
    dispatch(fetchUserFeedbacks({ limit: 3, page: 1, userId: options.id }));
    return {};
  }
);

export const fetchUserJobs = createAsyncThunk(
  "jobs/fetchUserJobs",
  async (options) => {
    const { data } = await jobsApi(options);
    return data;
  }
);

export const fetchUserFeedbacks = createAsyncThunk(
  "jobs/fetchUserFeedbacks",
  async (options) => {
    const { data } = await feedbackApi(options);
    return data;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: { list: [] },
    user: { jobs: { list: [] }, feedbacks: { list: [] } },
  },
  reducers: {
    resetUsers: (state) => {
      state.users = { list: [] };
    },
    resetUsers: (state) => {
      state.user = { jobs: { list: [] } };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {});
    builder.addCase(fetchNextUsers.fulfilled, (state, action) => {
      state.users.list = [...state.users.list, ...action.payload.list];
      state.users.page = action.payload.page;
      state.users.count = action.payload.count;
      state.users.pages = action.payload.pages;
    });
    builder.addCase(leaveUserFeedback.fulfilled, (state, action) => {
      state.user.can_leave_feedback = false;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users.list = action.payload.list;
      state.users.page = action.payload.page;
      state.users.count = action.payload.count;
      state.users.pages = action.payload.pages;
    });
    builder.addCase(fetchUsers.rejected, (state) => {});
    builder.addCase(fetchUser.pending, (state) => {});
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
    });
    builder.addCase(fetchUserJobs.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        jobs: { list: action.payload.list },
      };
    });
    builder.addCase(fetchUserFeedbacks.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        feedbacks: { list: action.payload.list },
      };
    });
    builder.addCase(fetchUser.rejected, (state) => {});
  },
});

export const { resetUsers, resetUser } = usersSlice.actions;

export default usersSlice.reducer;
