import React, { useRef, useEffect, useState } from "react";
import { Text, Animated, TextInput, View } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";

function useAnimatedValue(value) {
  return useRef(new Animated.Value(value)).current;
}
function useOscillatingValue({ duration = 1000, delay = 0 } = {}) {
  const x = useAnimatedValue(-1);

  useEffect(() => {
    const a = Animated.loop(
      Animated.timing(x, {
        toValue: 1,
        duration,
        delay,
        useNativeDriver: true,
      })
    );
    a.start();
    return () => a.stop();
  }, [x, duration, delay]);

  return x;
}

function Skeleton({
  width = 100,
  height,
  colors,
  duration,
  full = false,
  borderRadius = 0,
}) {
  const x = useOscillatingValue({ duration });
  const translateX = x.interpolate({
    inputRange: [-1, 1],
    outputRange: [width, -width],
  });

  return (
    <View
      style={{
        overflow: "hidden",
        height,
        borderRadius,
        width: full || width,
        justifyContent: "center",
        borderStyle: "solid",
        backgroundColor: colors[0],
        margin: 10,
      }}
    >
      <Animated.View
        style={{
          borderRadius: 10,
          height: "100%",
          width,
          transform: [
            {
              translateX,
            },
          ],
        }}
      >
        <LinearGradient
          style={{
            height: "100%",
            width,
          }}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={colors}
        />
      </Animated.View>
    </View>
  );
}

export default Skeleton;
