import { createNativeStackNavigator } from "@react-navigation/native-stack";
import MyProfilePage from "../pages/MyProfilePage";
import OpenDrawerButton from "../components/OpenDrawerButton";

const Stack = createNativeStackNavigator();

const commonDrawerPageOptions = {
  headerLeft: () => <OpenDrawerButton />,
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

const MyProfileStack = ({ route }) => {
  return (
    <Stack.Navigator initialParams={route.params}>
      <Stack.Screen
        name="MyProfilePage"
        component={MyProfilePage}
        options={{ title: "Профіль", ...commonDrawerPageOptions }}
      />
    </Stack.Navigator>
  );
};

export default MyProfileStack;
