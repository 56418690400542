const axios = require("axios").default;
var qs = require("qs");

export const usersApi = (params = {}) => {
  return axios.get(`/user/search?${qs.stringify(params)}`).then((r) => r.data);
};
export const userByIdApi = (id) => {
  return axios.get(`/user/${id}`).then((r) => r.data);
};

export const signInApi = (body) => {
  return axios.post(`/auth/login`, body);
};

export const signUpApi = (body) => {
  return axios.post(`/user/register`, body);
};

export const resetPasswordRequestApi = (phone) => {
  return axios.post(`/user/reset-pass-request`, { phone });
};

export const verifyResetPasswordCodeApi = (body) => {
  return axios.post(`/user/verify-reset-code`, body);
};

export const verifySignInCodeApi = (body) => {
  return axios.post(`/user/verify`, body);
};

export const resetPasswordApi = (body) => {
  return axios.post(`/user/reset-pass`, body);
};
export const getCurrentUserApi = () => {
  return axios.get(`/user/current`);
};

export const updateProfileApi = (body) => {
  return axios.post(`/user/update`, body);
};

export const deleteProfileApi = (id) => {
  return axios.delete(`/user/delete/${id}`);
};

export const addGalleryImage = (body) => {
  return axios.put(`/user/gallery`, body);
};

export const deleteGalleryImage = (id) => {
  return axios.delete(`/user/gallery/${id}`);
};

export const getFavoriteApi = () => {
  return axios.get(`/user/favorite`);
};

export const toggleFavoriteApi = (id) => {
  return axios.post(`/user/favorite/${id}`);
};

export const getFavoriteItemsApi = (params = {}) => {
  return axios
    .get(`/user/favorite-list?${qs.stringify(params)}`)
    .then((r) => r.data);
};

export const changePasswordApi = (body) => {
  return axios.put(`/user/change-pass`, body);
};
