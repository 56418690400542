import { StyleSheet, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";
import { useNavigation } from "@react-navigation/native";
import { size } from "lodash";
import { useLinkTo } from "@react-navigation/native";

export default function BackButton({ backToRoute, backLinkTo }) {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const routesLength = size(navigation.getState().routes);

  return (
    <Pressable
      onPress={() => {
        if (routesLength > 1) {
          navigation.goBack();
        } else {
          if (backLinkTo === "/") {
            navigation.reset({ routes: [{ name: "Feed" }] });
          } else {
            linkTo(backLinkTo);
          }
        }
      }}
      style={{ padding: 10 }}
    >
      <Icon name="chevron-left" color="white" size={16} />
    </Pressable>
  );
}
const styles = StyleSheet.create({});
