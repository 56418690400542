import { StyleSheet, View, Text } from "react-native";
import CustomButton from "../components/CustomButton";
import { useSelector } from "react-redux";
import { profileSelector } from "../store/user/user.selector";
import { RFPercentage } from "react-native-responsive-fontsize";
import { useLinkTo } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";

const GuardedButton = ({ children, onPress, ...rest }) => {
  const navigation = useNavigation();
  const { access_token } = useSelector(profileSelector);

  return (
    <CustomButton
      onPress={() => {
        if (access_token) {
          onPress();
        } else {
          navigation.navigate("SignIn");
        }
      }}
      {...rest}
    >
      {children}
    </CustomButton>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 32,
  },
  btn_box: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 7,
  },
  plus: {
    color: "#4C4C4C",
    fontSize: RFPercentage(1.2),
    marginLeft: 5,
    fontWeight: "bold",
  },
  btn_wrapp: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: 60,
    backgroundColor: "#209CFF",
    flex: 1,
    width: "100%",
  },
});

export default GuardedButton;
