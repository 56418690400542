import { StyleSheet, View, ScrollView } from "react-native";
import * as yup from "yup";
import { Formik } from "formik";
import CustomInput from "../components/CustomInput";
import CustomRadioButton from "../components/CustomRadioButton";
import CustomButton from "../components/CustomButton";
import { useSelector } from "react-redux";
import { map, xor } from "loadsh";
import CustomMultiselect from "../components/CustomMultiselect";
import CategoriesModal from "../components/modals/CategoriesModal";
import RegionsModal from "../components/modals/RegionsModal";
import React, { useRef, useState, useCallback } from "react";
import DatePicker from "../components/DatePicker";
import Gallery from "../components/Gallery/NativeGallery";

const createTaskSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Заголовок повинен бути не менше 4 символів.")
    .required("Заголовок обов'язковий."),
  description: yup
    .string()
    .min(100, "Опис повинен містити не менше 100 символів")
    .required("Потрібен опис"),
  categories: yup.array().min(1, "Зробіть хоча б один вибір"),
  regions: yup.array().min(1, "Зробіть хоча б один вибір"),
});

const priceTypeButtons = [
  {
    id: "1",
    label: "Фіксована",
    value: "fixed",
  },
  {
    id: "2",
    label: "Погодинна",
    value: "hourly",
    selected: true,
  },
];

const taskTypeButtons = [
  {
    id: "1",
    label: "Виконавця",
    value: "offer",
  },
  {
    id: "2",
    label: "Роботу",
    value: "find",
    selected: true,
  },
];

const _initialValues = {
  title: "",
  description: "",
  price: null || 0,
  categories: [],
  regions: [],
  priceType: "fixed",
  taskType: "find",
  executionDate: new Date(),
};

const _JobFormBase = ({
  initialValues = _initialValues,
  submitButtonText = "Створити",
  onSubmit = (d) => {},
  images = [],
  onAdd = () => {},
  onOrderChange = () => {},
  onDelete = () => {},
  mode = "create",
}) => {
  const {
    categories: [],
    regions: [],
  } = initialValues;

  const [showCatsModal, setShowCatsModal] = useState(false);
  const [showRegionsModal, setShowRegionsModal] = useState(false);
  const [height, setHeight] = useState(48);
  const formik = useRef(null);
  const categoriesList = useSelector((state) => state.categories.list);
  const regionsList = useSelector((state) => state.regions.list);

  const onCategoryChange = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "categories",
        xor(formik.current.values.categories, [id])
      );
    },
    [formik?.current?.values?.categories]
  );

  const onCategoryDelete = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "categories",
        formik.current.values.categories.filter((c) => c !== id)
      );
    },
    [formik?.current?.values?.categories]
  );

  const onRegionChange = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "regions",
        xor(formik.current.values.regions, [id])
      );
    },
    [formik?.current?.values?.regions]
  );

  const onRegionDelete = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "regions",
        formik.current.values.regions.filter((c) => c !== id)
      );
    },
    [formik?.current?.values?.regions]
  );

  const onSubmitForm = useCallback(
    (values) => {
      onSubmit({ ...values, gallery: images, price: Number(values.price) });
    },
    [images]
  );

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: "#ffffff" }}
    >
      <View style={styles.container}>
        <Formik
          innerRef={formik}
          enableReinitialize
          validationSchema={createTaskSchema}
          initialValues={initialValues}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            isValid,
            dirty,
          }) => (
            <View style={{ width: "100%", marginTop: 20 }}>
              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Назва"
                  label="Назва"
                  name="title"
                  onChangeText={handleChange("title")}
                  onBlur={handleBlur("title")}
                  value={values.title}
                  error={errors.title}
                />
              </View>
              <View style={styles.marginBottom}>
                <CustomInput
                  label="Ціна"
                  name="price"
                  onChangeText={handleChange("price")}
                  onBlur={handleBlur("price")}
                  keyboardType="numeric"
                  value={values.price}
                  error={errors.price}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomRadioButton
                  layout="row"
                  label={"Оплата"}
                  value={values.priceType}
                  options={priceTypeButtons}
                  error={
                    errors.priceType && touched.priceType
                      ? errors.priceType
                      : null
                  }
                  onChange={(value) => setFieldValue("priceType", value)}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomRadioButton
                  layout="row"
                  label={"Шукаю"}
                  value={values.taskType}
                  options={taskTypeButtons}
                  error={
                    errors.taskType && touched.taskType ? errors.taskType : null
                  }
                  onChange={(value) => setFieldValue("taskType", value)}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomMultiselect
                  label="Регіони"
                  onDelete={onRegionDelete}
                  items={map(
                    regionsList.filter((i) => values.regions.includes(i.id))
                  )}
                  onListView={() => {
                    setShowRegionsModal(true);
                  }}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomMultiselect
                  label="Категорії"
                  onDelete={onCategoryDelete}
                  items={map(
                    categoriesList.filter((i) =>
                      values.categories.includes(i.id)
                    )
                  )}
                  onListView={() => {
                    setShowCatsModal(true);
                  }}
                />
              </View>

              <View style={[styles.marginBottom, { zIndex: 2 }]}>
                <DatePicker
                  label={"Дата виконання"}
                  value={values.executionDate}
                  onChange={(value) => {
                    setFieldValue("executionDate", value);
                  }}
                />
              </View>
              <View style={styles.marginBottom}>
                <CustomInput
                  label="Опис послуги"
                  placeholder="Опишіть, що саме вам потрібно, в якому обсязі і за який термін"
                  name="description"
                  onChangeText={handleChange("description")}
                  onBlur={handleBlur("description")}
                  multiline
                  style={{ minHeight: height }}
                  onContentSizeChange={(v) => {
                    setHeight(v.nativeEvent.contentSize.height);
                  }}
                  value={values.description}
                  error={errors.description}
                />
              </View>
              <Gallery
                mode={mode}
                images={images}
                onAdd={onAdd}
                onOrderChange={onOrderChange}
                onDelete={onDelete}
              />
              <View style={styles.btn_box}>
                <CustomButton
                  text={submitButtonText}
                  onPress={handleSubmit}
                  disabled={!(isValid && dirty)}
                  full
                />
              </View>
              <CategoriesModal
                visible={showCatsModal}
                onClose={() => {
                  setShowCatsModal(false);
                }}
                onPress={onCategoryChange}
                selected={values.categories}
              />

              <RegionsModal
                visible={showRegionsModal}
                onClose={() => {
                  setShowRegionsModal(false);
                }}
                onPress={onRegionChange}
                selected={values.regions}
              ></RegionsModal>
            </View>
          )}
        </Formik>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 15,
    paddingBottom: 40,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
  },
  marginBottom: {
    marginBottom: 20,
    position: "relative",
  },
  btn_box: {
    marginTop: 10,
  },
});

export default React.memo(_JobFormBase);
