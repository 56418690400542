import {
  Text,
  Pressable,
  Image,
  StyleSheet,
  View,
  TouchableOpacity,
} from "react-native";
import CustomButton from "./CustomButton";
import Icon from "./icons/CustomIcon";
import TimeMoneyIcon from "./icons/TimeMoneyIcon";
import RoundButton from "./RoundButton";
import moment from "moment";

const UserListItem = ({
  title,
  favorite = false,
  description,
  secondName = " ",
  image,
  price,
  onPress,
  firstName,
  taskType,
  createdAt,
  feedbacksCount,
  onFavorite,
  rightTopSlot = <></>,
  bottomSlot = <></>,
}) => (
  <>
    <View style={styles.item}>
      <View style={styles.card_header}>
        <View style={styles.header_info}>
          <View style={styles.finder}>
            <Text
              style={[
                styles.gray_text,
                { textDecorationLine: "underline", marginRight: 3 },
              ]}
            >
              {taskType === "find" ? "Шукаю роботу" : "Пропоную роботу"}
            </Text>
            <Icon name="light-bulb" color="#A0A0A0" size={11} />
          </View>
          <View style={styles.flex_row}>
            <Icon name="calendar" color="#A0A0A0" size={11} />
            <Text style={[styles.gray_text, styles.createdAt]}>
              {moment(createdAt).format("L")}
            </Text>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <RoundButton
            onPress={onFavorite}
            iconName={"star-empty"}
            backgroundColor={favorite ? "orange" : "white"}
            iconColor={favorite ? "white" : "#4F4F4F"}
          />
          {rightTopSlot}
        </View>
      </View>

      <View style={styles.card_body}>
        <View style={styles.main}>
          {image ? (
            <Image source={{ uri: image }} style={styles.avatar_img} />
          ) : (
            <View style={styles.avatar_img}>
              <Icon name="light-bulb" color="#e0e0e0" size={50} />
            </View>
          )}

          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text style={styles.title}>{title}</Text>
            <View
              style={[styles.flex_row, { justifyContent: "space-between" }]}
            >
              <View style={styles.flex_row}>
                <Icon name="user" color="#A0A0A0" size={11} />
                <Text style={styles.gray_text}>
                  {" "}
                  {firstName} {secondName?.charAt(0)}.
                </Text>
              </View>
              <View style={styles.flex_row}>
                <TimeMoneyIcon />
                <Text style={styles.price_text}>{price ? price + ' грн' : 'договірна'}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.card_footer}>
        <CustomButton size="xs" full text="Детальніше" onPress={onPress} />
        {bottomSlot}
      </View>
    </View>
  </>
);

const styles = StyleSheet.create({
  item: {
    backgroundColor: "#ffffff",
    width: "97%",
    alignSelf: "center",
    marginVertical: 5,
    borderColor: "#E0E0E0",
    borderWidth: 1,
    borderRadius: 8,
    overflow: "hidden",
  },
  createdAt: {
    marginLeft: 10,
  },
  card_header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    backgroundColor: "#F9F9F9",
  },
  card_body: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  card_footer: {
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F9F9F9",
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  header_info: {
    flexDirection: "row",
    alignItems: "center",
  },
  gray_text: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
  },
  finder: {
    marginRight: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  star_box: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  main: {
    alignItems: "flex-start",
    flexDirection: "row",
  },
  title: {
    fontSize: 14,
    lineHeight: 23,
    color: "#4C4C4C",
    fontWeight: "bold",
    paddingTop: 8,
    marginBottom: 25,
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  price_text: {
    color: "#27AE60",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  avatar_img: {
    width: 115,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default UserListItem;
