import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Categories from "../pages/Categories";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import JobsSearch from "../pages/JobsSearch";
import JobDetails from "../pages/JobDetails";
import FavoritePage from "../pages/FavoritePage";
import MyJobsPage from "../pages/MyJobsPage";
import ProfileDetails from "../pages/ProfileDetails/ProfileDetails";
import ProfileFeedbacks from "../pages/ProfileFeedbacks";
import ProfileActualJobs from "../pages/ProfileActualJobs";
import CreateJob from "../pages/CreateJob";
import LeaveFeedbackPage from "../pages/LeaveFeedbackPage";
import OpenDrawerButton from "../components/OpenDrawerButton";
import BackButton from "../components/BackButton";
import SearchFilters from "../pages/SearchFilters";
import PrivateChat from "../pages/chat/PrivateChat";

import Search from "../pages/Search";
import EditJob from "../pages/EditJob/EditJob";
import { useEffect } from "react";
import { fetchCategories } from "../store/categories";
import { fetchRegions } from "../store/regions";
import SearchJobsInput from "../components/SearchJobsInput";

const Stack = createNativeStackNavigator();

const commonTitleStyle = {
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

const commonOptions = {
  headerStyle: { backgroundColor: "#209CFF" },
};

export default function MainStack() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchRegions());
  }, []);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Categories"
        component={Categories}
        options={{
          title: "",
          headerTitleStyle: {
            color: "white",
          },
          headerLeft: () => (
            <View style={{ width: 35, marginRight: 20 }}>
              <OpenDrawerButton />
            </View>
          ),
          headerTitle: () => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                width: "100%",
              }}
            >
              <SearchJobsInput width={"100%"} />
            </View>
          ),
          headerStyle: { backgroundColor: "#209CFF", display: "flex" },
        }}
      />
      <Stack.Screen
        name="JobsSearch"
        component={JobsSearch}
        options={({ route }) => ({
          ...commonTitleStyle,
          title: "",
          // headerLeft: () => <BackButton backLinkTo={'/'}/>,
          // options={{ headerShown: false }}
        })}
      />

      <Stack.Screen
        name="Search"
        component={Search}
        options={{
          title: "",
          headerTitleStyle: {
            color: "blue",
            maxWidth: 1000,
          },
        }}
      />

      <Stack.Screen
        name="FavoritePage"
        component={FavoritePage}
        options={({ route }) => ({
          title: "Обрані завдання",
          ...commonTitleStyle,
          headerLeft: () => <OpenDrawerButton />,
        })}
      />
      <Stack.Screen
        name="MyJobsPage"
        component={MyJobsPage}
        options={({ route }) => ({
          ...commonTitleStyle,
          title: "Мої завдання",
          headerLeft: () => <OpenDrawerButton />,
        })}
      />

      <Stack.Screen
        name="JobDetails"
        component={JobDetails}
        options={({ route }) => ({
          title: "Деталі оголошення",
          ...commonTitleStyle,
          headerLeft: () => <BackButton backLinkTo={'/'}/>
        })}
      />

      <Stack.Screen
        name="ProfileDetails"
        component={ProfileDetails}
        options={({ route }) => ({
          title: route.params.title,
          headerLeft: () => <BackButton backLinkTo={'/'}/>,
          ...commonTitleStyle,
        })}
      />

      <Stack.Screen
        name="PrivateChat"
        component={PrivateChat}
        options={{
          ...commonTitleStyle,
          title: "",
          headerLeft: () => <BackButton backLinkTo={"/cabinet/chat"} />,
        }}
      />

      <Stack.Screen
        name="ProfileFeedbacks"
        component={ProfileFeedbacks}
        options={({ route }) => ({
          ...commonTitleStyle,
        })}
      />

      <Stack.Screen
        name="ProfileActualJobs"
        component={ProfileActualJobs}
        options={({ route }) => ({
          ...commonTitleStyle,
        })}
      />

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="SearchFilters"
          component={SearchFilters}
          options={{ title: "Фільтри", ...commonTitleStyle }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="CreateJob"
          component={CreateJob}
          options={{
            ...commonTitleStyle,
            title: "Створити завдання",
            headerLeft: () => <BackButton backLinkTo={"/"} />,
          }}
        />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="EditJob"
          component={EditJob}
          options={{
            ...commonTitleStyle,
            title: "Редагувати завдання",
            headerLeft: () => <BackButton backLinkTo={"/cabinet/task-list"} />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="LeaveFeedback"
          component={LeaveFeedbackPage}
          options={{
            title: "Залишити відгук",
            headerStyle: { backgroundColor: "#209CFF" },
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
