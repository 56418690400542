import { Modal, View, StyleSheet } from "react-native";
import { SafeAreaView, FlatList } from "react-native";
import _ from "loadsh";
import { useSelector } from "react-redux";
import Header from "../Header";
import CategoryItem from "../CategoryItem";

export default function SubCategoriesModal({
  parentCat,
  onClose,
  visible,
  setSubModalIsVisible,
  navigation,
}) {
  const categories = useSelector((state) => state.categories.list);
  return (
    <Modal animationType={"slide"} transparent={false} visible={visible}>
      <SafeAreaView style={{ flex: 1, position: "relative" }}>
        <Header onBackPress={onClose} title={parentCat.name} />
        <View style={styles.container}>
          <FlatList
            data={categories.filter((e) => e.parentId === parentCat.id)}
            renderItem={({ item }) => (
              <CategoryItem
                {...item}
                onPress={() => {
                  navigation.push("JobsSearch", { alias: item.alias });
                  setSubModalIsVisible(false);
                }}
                sub={false}
              />
            )}
            keyExtractor={(item) => item.id}
          />
        </View>
        {/* <CreateJobButtons></CreateJobButtons> */}
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
});
