import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import { useState } from "react";

const styles = StyleSheet.create({
  marginBottom30: {
    marginBottom: 30,
  },
  text: {
    textAlign: "center",
    width: "100%",
    fontSize: 19,
  },
  sendAgain: {
    textAlign: "center",
    width: "100%",
    fontSize: 19,
    color: "#007AFF",

    marginTop: 20,
  },
  root: { padding: 20, minHeight: 300 },
  title: { textAlign: "center", fontSize: 30 },
  codeFiledRoot: {
    marginTop: 20,
    width: 280,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    justifyContent: "center",
  },
  cellRoot: {
    width: 50,
    height: 40,
    margin: 10,
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
  },
  cellText: {
    color: "#000",
    fontSize: 36,
    textAlign: "center",
  },
  focusCell: {
    borderBottomColor: "#007AFF",
    borderBottomWidth: 2,
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: 10,
  },
});

const VerificationCode = ({
  error = null,
  phone = "",
  onVerify = () => {},
}) => {
  const CELL_COUNT = 4;
  const [value, setValue] = useState("");
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <View>
          <Text style={styles.text}>4-значний код був надісланий на</Text>
        </View>
        <View>
          {/* <Text style={[styles.text, styles.marginBottom30]} >{route.params.phone}</Text> */}
          <Text style={[styles.text, styles.marginBottom30]}>{phone}</Text>
        </View>
        {error && <Text style={styles.error}>Невірний код</Text>}
        <CodeField
          ref={ref}
          {...props}
          value={value}
          onChangeText={(v) => {
            setValue(v);
            if (v.length === 4) {
              onVerify(v);
              setValue("");
            }
          }}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFiledRoot}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          renderCell={({ index, symbol, isFocused }) => (
            <View
              onLayout={getCellOnLayoutHandler(index)}
              key={index}
              style={[styles.cellRoot, isFocused && styles.focusCell]}
            >
              <Text style={styles.cellText}>
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            </View>
          )}
        />

        <View>
          <TouchableOpacity
            onPress={() => {
              //  dispatch(SignUpAction(route.params))
            }}
          >
            <Text style={styles.sendAgain}>ВІДПРАВИТИ ЩЕ РАЗ</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default VerificationCode;
