import { View } from "react-native";
import BurgerIcon from "../components/icons/BurgerIcon";
import { useNavigation } from "@react-navigation/native";

const OpenDrawerButton = () => {
  const navigation = useNavigation();
  return (
    <View>
      <BurgerIcon
        onPress={() => {
          navigation.openDrawer();
        }}
      ></BurgerIcon>
    </View>
  );
};

export default OpenDrawerButton;
