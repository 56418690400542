import { StyleSheet, View, ScrollView, Platform } from "react-native";
import * as yup from "yup";
import { Formik } from "formik";
import CustomInput from "../components/CustomInput";
import CustomRadioButton from "../components/CustomRadioButton";
import CustomButton from "../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useRef, useState } from "react";
import { getProfileAction } from "../store/user";
import { map, xor, range } from "loadsh";
import CustomMultiselect from "../components/CustomMultiselect";
import { updateProfileAction } from "../store/user";
import { profileSelector } from "../store/user/user.selector";
import { useRemoveUser } from "../hooks";
import {
  deleteGalleryImage,
  addGalleryImage,
  getCurrentUserApi,
} from "../api/users.api";
import { useToast } from "react-native-toast-notifications";
import CategoriesModal from "../components/modals/CategoriesModal";
import RegionsModal from "../components/modals/RegionsModal";
import ChangePassModal from "../components/modals/ChangePassModal";
import DeleteAccountModal from "../components/modals/DeleteAccountModal";
import Gallery from "../components/Gallery/NativeGallery";
import { useQuery } from "react-query";

const loginValidationSchema = yup.object().shape({
  title: yup.string().required("Потріна назва").nullable(),
  nickName: yup.string().required("Потріно прізвисько").nullable(),
  firstName: yup.string().required(`Ім'я обов'язково`).nullable(),
  secondName: yup.string().nullable(),
  thirdName: yup.string().nullable(),
  priceType: yup.string().nullable(),
  nickName: yup.string().required(`Псевдонім обов'язково.`).nullable(),
  email: yup.string().email().required("Email is not valid").nullable(),
  price: yup.number().required().nullable(),
  description: yup
    .string()
    .min(100, "Опис повинен містити не менше 100 символів")
    .required("Потрібен опис")
    .nullable(),
  categories: yup.array(),
  regions: yup.array(),
});

const radioButtons = [
  {
    id: "1",
    label: "Фіксована",
    value: "fixed",
  },
  {
    id: "2",
    label: "Погодинна",
    value: "hourly",
    selected: true,
  },
];

export default function MyProfilePage({ navigation, user = {} }) {
  const { id } = useSelector(profileSelector);
  const deleteAccount = useRemoveUser();
  const toast = useToast();
  const dispatch = useDispatch();
  const [height, setHeight] = useState(48);
  const { data: initialValues, isFetching } = useQuery(
    ["current"],
    () => getCurrentUserApi().then((d) => d.data),
    {
      select: (data) => ({...data, categories: map(data.categories,'id'),  regions: map(data.regions,'id')}),
      initialData: {
        firstName: "",
        nickName: "",
        title: "",
        secondName: "",
        thirdName: "",
        phone: "",
        email: "",
        description: "",
        price: 0,
        priceType: "fixed",
        categories: [],
        regions: [],
        gallery: [],
      },
    },
    
  );

  const formik = useRef(null);
  const categoriesList = useSelector((state) => state.categories.list);
  const regionsList = useSelector((state) => state.regions.list);
  const [showCatsModal, setShowCatsModal] = useState(false);
  const [showRegionsModal, setShowRegionsModal] = useState(false);
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const [showConfirmDeleteAccount, confirmDeleteAccount] =  useState({
    visible: false,
    payload: null,
  });
  const [gallery, setGallery] = useState(range(6).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true})));

  useEffect(() => {
    const _l = initialValues.gallery.length;
    const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
    const new_gallery = initialValues.gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
    setGallery([...new_gallery, ...disabled_items])
  }, [initialValues]);

  const onCategoryChange = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "categories",
        xor(formik.current.values.categories, [id])
      );
    },
    [formik?.current?.values?.categories]
  );

  const onCategoryDelete = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "categories",
        formik.current.values.categories.filter((c) => c !== id)
      );
    },
    [formik?.current?.values?.categories]
  );

  const onRegionChange = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "regions",
        xor(formik.current.values.regions, [id])
      );
    },
    [formik?.current?.values?.regions]
  );

  const onRegionDelete = useCallback(
    (id) => {
      formik.current.setFieldValue(
        "regions",
        formik.current.values.regions.filter((c) => c !== id)
      );
    },
    [formik?.current?.values?.regions]
  );

  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  const setImageLoading = useCallback(
    (index, state) => {
      setGallery((p) => {
        return p.map((v, i) => {
          return { ...v, loading: index === i ? state : false };
        });
      });
    },
    [gallery]
  );


  const onOrderChange = useCallback((files) => {
    setGallery(files);
  },[gallery])

  const onGalleryDelete = useCallback(
    async (id) => {
      // setImageLoading(index, true);
      await deleteGalleryImage(id);
      const filtered_gallery = gallery.filter(i  => i.id && i.id !== id)
      const _l = filtered_gallery.length;
      const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
      const new_gallery = filtered_gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
      setGallery([...new_gallery, ...disabled_items])
    },
    [gallery, initialValues,setGallery]
  );

  const onAddGallery = useCallback(
    async (image) => {
      const prev_state = gallery;

      const formData = new FormData();
      if (Platform.OS === "web") {
        formData.append("image", image.file);
      } else {
        formData.append("image", { ...image, type: image.mimeType });
      }
      const { data } = await addGalleryImage(formData);
      const filtered_gallery = [...gallery.filter(i  => i.id),data]
      const _l = filtered_gallery.length;
      const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
      const new_gallery = filtered_gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
      setGallery([...new_gallery, ...disabled_items])
    },
    [gallery, initialValues]
  );

  const onRemoveAccount = useCallback(async () => {
    deleteAccount.mutate(id);
    confirmDeleteAccount({ visible: false, payload: {} });
  }, [showConfirmDeleteAccount, deleteAccount]);
  
  const onCancel = useCallback(() => {
    confirmDeleteAccount({ visible: false, payload: {} });
  }, []);

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: "#ffffff" }}
    >
      <View style={styles.container}>
        <Formik
          enableReinitialize
          innerRef={formik}
          validationSchema={loginValidationSchema}
          initialValues={initialValues}
          onSubmit={async (values) => {
            const { error } = await dispatch(updateProfileAction(values));
            if (error) {
              toast.show("Error", { placement: "top", type: "danger" });
            } else {
              toast.show("Профайл успішно оновлений", {
                placement: "top",
                type: "success",
              });
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            isValid,
            dirty,
          }) => (
            <View style={{ width: "100%", marginTop: 20 }}>
              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Назва"
                  label="Назва"
                  name="title"
                  onChangeText={handleChange("title")}
                  onBlur={handleBlur("title")}
                  value={values.title || ""}
                  error={errors.title}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Ім'я"
                  label="Ім'я"
                  name="firstName"
                  onChangeText={handleChange("firstName")}
                  onBlur={handleBlur("firstName")}
                  value={values.firstName || ""}
                  error={errors.firstName}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Прізвисько"
                  label="Прізвисько"
                  name="nickName"
                  onChangeText={handleChange("nickName")}
                  onBlur={handleBlur("nickName")}
                  value={values.nickName || ""}
                  error={errors.nickName}
                />
              </View> 

              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Прізвище"
                  label="Прізвище"
                  name="secondName"
                  onChangeText={handleChange("secondName")}
                  onBlur={handleBlur("secondName")}
                  value={values.secondName || ""}
                  error={errors.secondName}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="По батькові"
                  label="По батькові"
                  name="thirdName"
                  onChangeText={handleChange("thirdName")}
                  onBlur={handleBlur("thirdName")}
                  value={values.thirdName || ""}
                  error={errors.thirdName}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Введіть номер телефону..."
                  label="номер телефону"
                  name="phone"
                  disabled
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={values.phone || ""}
                  error={errors.phone}
                />
              </View> 

              <View style={styles.marginBottom}>
                <CustomInput
                  placeholder="Email"
                  label="Email"
                  name="email"
                  onChangeText={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email || ""}
                  error={errors.email}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomInput
                  label="Ціна"
                  name="price"
                  onChangeText={handleChange("price")}
                  onBlur={handleBlur("price")}
                  keyboardType="numeric"
                  value={values.price}
                  error={errors.price}
                />
              </View>
              <View style={styles.marginBottom}>
                <CustomRadioButton
                  layout="row"
                  label={"Оплата"}
                  value={values.priceType}
                  options={radioButtons}
                  error={
                    errors.priceType && touched.priceType
                      ? errors.priceType
                      : null
                  }
                  onChange={(value) => setFieldValue("priceType", value)}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomMultiselect
                  label="Категорії"
                  onDelete={onCategoryDelete}
                  items={map(
                    categoriesList.filter((i) =>
                      values.categories.includes(i.id)
                    )
                  )}
                  onListView={() => {
                    setShowCatsModal(true);
                  }}
                />
              </View>
              <View style={styles.marginBottom}>
                <CustomMultiselect
                  label="Регіони"
                  onDelete={onRegionDelete}
                  items={map(
                    regionsList.filter((i) => values.regions.includes(i.id))
                  )}
                  onListView={() => {
                    setShowRegionsModal(true);
                  }}
                />
              </View>

              <View style={styles.marginBottom}>
                <CustomInput
                  label="Опис послуги"
                  placeholder="Опишіть, що саме вам потрібно, в якому обсязі і за який термін"
                  name="description"
                  onChangeText={handleChange("description")}
                  onBlur={handleBlur("description")}
                  multiline
                  style={{ minHeight: height }}
                  onContentSizeChange={(v) => {
                    setHeight(v.nativeEvent.contentSize.height);
                  }}
                  value={values.description || ""}
                  error={errors.description}
                />
              </View>
              <View style={styles.marginBottom}>
                <Gallery
                  mode={"edit"}
                  images={gallery.map((f) => ({ ...f, uri: f.fileName }))}
                  onAdd={onAddGallery}
                  onOrderChange={onOrderChange}
                  onDelete={onGalleryDelete}
                />
              </View>
              <View style={styles.btn_box}>
                <CustomButton
                  text="Обновити"
                  onPress={handleSubmit}
                  disabled={!isValid}
                  full
                />
              </View>
              <View style={styles.btn_box}>
                <CustomButton
                  text="Змінити пароль"
                  onPress={() => setShowChangePassModal(true)}
                  full
                />
              </View>
              <View style={styles.btn_box}>
                <CustomButton
                  text="Видалити обліковий запис"
                  onPress={() => confirmDeleteAccount({ visible: true })}
                  full
                />
              </View>
              <CategoriesModal
                visible={showCatsModal}
                onClose={() => {
                  setShowCatsModal(false);
                }}
                onPress={onCategoryChange}
                selected={values.categories}
              />

              <RegionsModal
                visible={showRegionsModal}
                onClose={() => {
                  setShowRegionsModal(false);
                }}
                onPress={onRegionChange}
                selected={values.regions}
              ></RegionsModal>
            </View>
          )}
        </Formik>
        <ChangePassModal
          visible={showChangePassModal}
          setShowChangePassModal={setShowChangePassModal}
        ></ChangePassModal>
        <DeleteAccountModal
          visible={showConfirmDeleteAccount.visible}
          onRemoveAccount={onRemoveAccount}
          onCancel={onCancel}
        ></DeleteAccountModal>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 15,
    paddingBottom: 40,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
  },
  marginBottom: {
    marginBottom: 20,
  },
  btn_box: {
    marginTop: 10,
  },
});
