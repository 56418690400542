import { Text, StyleSheet, View } from "react-native";
import Icon from "./icons/CustomIcon";

export default function RatingDetailed({
  politeness = 0,
  quality = 0,
  punctuality = 0,
  rating = 0,
  feedbacksAmount = 0,
}) {
  return (
    <View style={styles.item}>
      <View style={[styles.header, styles.flex_row_between]}>
        <View style={styles.flex_row}>
          <Text style={styles.score_text}>{(Number(rating) / 5) * 100}%</Text>
          <Text style={[styles.dark_text, { width: 70 }]}>
            Позитивних відгуків
          </Text>
        </View>
        <View style={[styles.rating, styles.flex_row]}>
          <Icon name="star-full" size={22} color="#F2994A" />
          <Icon name="star-full" size={22} color="#F2994A" />
          <Icon name="star-full" size={22} color="#F2994A" />
          <Icon name="star-full" size={22} color="#F2994A" />
          <Icon name="star-full" size={22} color="#F2994A" />
          <Text style={styles.rating_score}>{rating}</Text>
        </View>
      </View>
      <Text style={styles.dark_text}>
        {feedbacksAmount} відгуків в цій категорії
      </Text>
      <View style={styles.progress_box}>
        <View style={[styles.flex_row_between, { marginBottom: 10 }]}>
          <Text style={styles.progress_title}>Якість виконаної роботи</Text>
          <Text style={styles.progress_score}>{quality}%</Text>
        </View>
        <View style={styles.progress}>
          <View style={[styles.progress_bar, { width: quality + "%" }]}></View>
        </View>
      </View>
      <View style={styles.progress_box}>
        <View style={[styles.flex_row_between, { marginBottom: 10 }]}>
          <Text style={styles.progress_title}>Пунктуальність</Text>
          <Text style={styles.progress_score}>{punctuality} %</Text>
        </View>
        <View style={styles.progress}>
          <View
            style={[styles.progress_bar, { width: punctuality + "%" }]}
          ></View>
        </View>
      </View>
      <View style={styles.progress_box}>
        <View style={[styles.flex_row_between, { marginBottom: 10 }]}>
          <Text style={styles.progress_title}>Вічливість</Text>
          <Text style={styles.progress_score}>{politeness}%</Text>
        </View>
        <View style={styles.progress}>
          <View
            style={[styles.progress_bar, { width: politeness + "%" }]}
          ></View>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  item: {
    marginHorizontal: 10,
    marginBottom: 20,
  },
  header: {
    marginBottom: 15,
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  flex_row_between: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dark_text: {
    fontSize: 12,
    lineHeight: 17,
    color: "#4C4C4C",
  },
  score_text: {
    fontSize: 36,
    lineHeight: 41,
    color: "#4C4C4C",
    marginRight: 5,
  },
  rating_score: {
    fontSize: 18,
    lineHeight: 21,
    color: "#F2994A",
    fontWeight: "bold",
    marginLeft: 5,
  },
  progress_box: {
    marginTop: 15,
  },
  progress_title: {
    fontSize: 14,
    lineHeight: 17,
    color: "#4C4C4C",
    fontWeight: "bold",
  },
  progress_score: {
    fontSize: 16,
    lineHeight: 22,
    color: "#F2994A",
    fontWeight: "bold",
  },
  progress: {
    backgroundColor: "#E0E0E0",
    height: 5,
  },
  progress_bar: {
    backgroundColor: "#F2994A",
    height: "100%",
  },
});
