import { useMemo } from "react";
import Swiper from "react-native-web-swiper";

const SwiperWrapper = ({ children, ...rest }) => {
  const keyValue = useMemo(
    () => Math.random().toString(16).slice(2),
    [children]
  );
  return <Swiper key={keyValue} {...rest}>{children}</Swiper>;
};
export default SwiperWrapper;
