import {
  Modal,
  Text,
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { Formik } from "formik";
import * as yup from "yup";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import Header from "../Header";
import { changePasswordApi } from "../../api/users.api";
import { useToast } from "react-native-toast-notifications";
import Toast from "react-native-toast-notifications";
import { useRef, useCallback } from "react";

export default function ChangePassModal({ visible, setShowChangePassModal }) {
  const toastRef = useRef();
  const toast = useToast();
  const schema = yup.object().shape({
    old_password: yup.string().required("Поле є обовʼязковим"),
    new_password: yup.string().required("Поле є обовʼязковим"),
    passwordConfirmation: yup
      .string()
      .required("Поле є обовʼязковим")
      .oneOf([yup.ref("new_password"), null], "Паролі не збігаються"),
  });
  const onSubmit = useCallback(
    async (values) => {
      const { old_password, new_password } = values;
      try {
        await changePasswordApi({ new_password, old_password });
        setShowChangePassModal(false);
        toast.show("Новий пароль збережено.", {
          placement: "top",
          type: "success",
        });
      } catch (e) {
        toastRef.current.show(e.response.data.message, {
          placement: "top",
          type: "danger",
        });
      }
    },
    [toast]
  );

  return (
    <Modal
      animationType={"slide"}
      visible={visible}
      style={{ backgroundColor: "white" }}
      transparent={true}
    >
      <Header
        onBackPress={() => setShowChangePassModal(false)}
        title={"Змінити пароль"}
      />
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <Formik
          initialValues={{ old_password: "", new_password: "" }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            values,
            errors,
            touched,
          }) => (
            <View style={styles.container}>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Старий пароль"
                  onChangeText={handleChange("old_password")}
                  onBlur={handleBlur("old_password")}
                  value={values.old_password}
                  error={
                    errors.old_password && touched.old_password
                      ? errors.old_password
                      : null
                  }
                  secureTextEntry
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Новий пароль"
                  onChangeText={handleChange("new_password")}
                  onBlur={handleBlur("new_password")}
                  value={values.new_password}
                  error={
                    errors.new_password && touched.new_password
                      ? errors.new_password
                      : null
                  }
                  secureTextEntry
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Підтвердити новий пароль"
                  onChangeText={handleChange("passwordConfirmation")}
                  onBlur={handleBlur("passwordConfirmation")}
                  value={values.passwordConfirmation}
                  error={
                    errors.passwordConfirmation && touched.passwordConfirmation
                      ? errors.passwordConfirmation
                      : null
                  }
                  secureTextEntry
                />
              </View>
              <View style={styles.form_control}>
                <CustomButton
                  type="submit"
                  full
                  text="Змінити пароль"
                  onPress={() => handleSubmit(values)}
                  disabled={false}
                />
              </View>
            </View>
          )}
        </Formik>
        <Toast ref={toastRef} />
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    alignItems: "center",
    paddingHorizontal: 15,
    paddingBottom: 40,
    paddingTop: 40,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
  },

  form_control: {
    width: "100%",
    marginBottom: 20,
  },
});
