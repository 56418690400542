const axios = require("axios").default;
var qs = require("qs");

export const lastMessagesApi = (params = {}) => {
  return axios
    .get(`chat/last-messages?${qs.stringify(params)}`)
    .then((d) => d.data);
};

export const favoriteIdsApi = () => {
  return axios.get(`chat/favorite-ids`).then((d) => d.data);
};

export const toggleFavorite = (roomId) => {
  return axios.post(`chat/favorite`, { roomId }).then((d) => d.data);
};
export const deleteRoomApi = (id) => {
  return axios.delete(`chat/room/${id}`).then((d) => d.data);
};
