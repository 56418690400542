import { StyleSheet, View, Text, Image } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import MainStack from "./MainStack";
import BlogStack from './BlogStack';
import DashboardPage from "../pages/DashBoardPage";

import OpenDrawerButton from "../components/OpenDrawerButton";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from "@react-navigation/drawer";
import MyProfileStack from "./MyProfileStack";
import AuthStack from "./AuthStack";
import Icon from "../components/icons/CustomIcon";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction, getFavoriteAction } from "../store/user";
import { useEffect } from "react";
import { profileSelector } from "../store/user/user.selector";
import { logOut } from "../store/user";
import ChatStack from "./ChatStack";
import BlogsPage from '../pages/BlogsPage';

const commonDrawerPageOptions = {
  headerLeft: () => <OpenDrawerButton />,
  headerStyle: { backgroundColor: "#209CFF" },
};

function CustomDrawerContent(props) {
  const {
    access_token,
    firstName,
    secondName,
    thirdName,
    nickName,
    avatarUrl,
  } = useSelector(profileSelector);
  const dispatch = useDispatch();

  return (
    <DrawerContentScrollView {...props}>
      {access_token && (
        <View style={styles.user_info}>
          <View style={styles.avatar_wrap}>
            {avatarUrl ? (
              <Image source={{ uri: avatarUrl }} style={styles.avatar_img} />
            ) : (
              <Icon name="user" color="#E0E0E0" size={30} />
            )}
          </View>
          <Text style={styles.user_info_nick_name}>{nickName}</Text>
          <Text style={styles.user_info_full_name}>
            {firstName} {secondName} {thirdName}
          </Text>
        </View>
      )}

      {/* {access_token && (
        <DrawerItem
          label="Панель користувача"
          onPress={() => {
            props.navigation.navigate("Dashboard");
          }}
        />
      )} */}

      <DrawerItem
        label="Головна"
        onPress={() => {
          props.navigation.navigate("Feed", {
            screen: "Categories",
          });
          // props.navigation.reset({
          //   routes: [
          //     { name: "Feed", screen: 'Categories'}
          //   ]
          // });
        }}
      />

      {access_token && (
        <DrawerItem
          label="Мої завдання"
          onPress={() => {
            props.navigation.navigate("Feed", {
              screen: "MyJobsPage",
            });
          }}
        />
      )}

      {access_token && (
        <DrawerItem
          label="Обрані завдання"
          onPress={() => {
            props.navigation.navigate("Feed", {
              screen: "FavoritePage",
            });
          }}
        />
      )}

      {access_token && (
        <DrawerItem
          label="Повідомлення"
          onPress={() => {
            props.navigation.navigate("ChatStack");
          }}
        />
      )}

      {access_token && (
        <DrawerItem
          label="Профіль"
          onPress={() => {
            props.navigation.navigate("ProfileStack", {
              screen: "MyProfilePage",
            });
          }}
        />
      )}

        <DrawerItem
          label="Статті"
          onPress={() => {
            props.navigation.navigate("BlogStack", { screen: 'BlogsPage'});
          }}
        />
    
      {!access_token ? (
        <>
          <DrawerItem
            label="Увійти"
            onPress={() => {
              props.navigation.navigate("AuthStack", {
                screen: "SignIn",
              });
              // props.navigation.push("SignIn");
            }}
          />
          <DrawerItem
            label="Зареєструватися"
            onPress={() => {
              props.navigation.navigate("AuthStack", {
                screen: "SignUp",
              });
              //  props.navigation.navigate("SignUp");
            }}
          />
        </>
      ) : (
        <DrawerItem
          label="Вийти"
          onPress={() => {
            dispatch(logOut());
            props.navigation.reset({
              routes: [{ name: "Feed", screen: "Categories" }],
            });
          }}
        />
      )}
    </DrawerContentScrollView>
  );
}

const Drawer = createDrawerNavigator();

const DrawerStack = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileAction());
    dispatch(getFavoriteAction());
  }, []);

  return (
    <Drawer.Navigator
      useLegacyImplementation
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        drawerType: "slide",
        drawerStyle: {
          backgroundColor: "white",
        },
      }}
    >
      <Drawer.Screen
        name="Feed"
        component={MainStack}
        options={{ title: "Головна", headerShown: false }}
      />
      {/* <Drawer.Screen
        name="Dashboard"
        component={DashboardPage}
        options={{ title: "Панель користувача", ...commonDrawerPageOptions }}
      /> */}
      <Drawer.Screen
        name="ProfileStack"
        component={MyProfileStack}
        options={{ title: "Профіль", headerShown: false }}
      />

      <Drawer.Screen
        name="BlogStack"
        component={BlogStack}
        options={{ headerShown: false }}
      />


      <Drawer.Screen
        name="ChatStack"
        component={ChatStack}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="AuthStack"
        component={AuthStack}
        options={{ headerShown: false }}
      />

      {/* <Drawer.Screen
        name="FavoritePage"
        component={FavoritePage}
        options={{ title: "Обрані завдання", ...commonDrawerPageOptions }}
      /> */}
      {/* <Drawer.Screen
        name="MyJobsPage"
        component={MyJobsPage}
        options={{ title: "Мої завдання", ...commonDrawerPageOptions }}
      /> */}
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  drawer_item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 10,
  },
  new: {
    width: 26,
    height: 26,
    borderRadius: 13,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2FCB16",
  },
  new_text: {
    color: "#F9F9F9",
    fontWeight: "bold",
    fontSize: 14,
  },
  avatar: {
    position: "relative",
  },
  avatar_wrap: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderWidth: 2,
    borderColor: "#E0E0E0",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: 10,
  },
  avatar_img: {
    width: "110%",
    height: "110%",
    resizeMode: "cover",
  },
  user_info: {
    padding: 15,
    borderBottomWidth: 1,
    borderColor: "#e0e0e0",
  },
  user_info_nick_name: {
    fontSize: 20,
    fontWeight: "500",
  },
  user_info_full_name: {
    color: "#0000008a",
    fontSize: 14,
  },
});

export default DrawerStack;
