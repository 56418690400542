import { Platform, View } from "react-native";

const DatePicker = (props) => {
  let DatePicker;
  if (Platform.OS === "web") {
    DatePicker = require("./WebDatePicker.js").default;
  } else {
    DatePicker = require("./NativeDatePicker.js").default;
  }
  return (
    <View>
      <DatePicker {...props} style={{ border: "1px solid blue" }}></DatePicker>
    </View>
  );
};

export default DatePicker;
