import { useState, useCallback } from "react";
import { useLinkTo } from "@react-navigation/native";
import SearchInput from "../components/SearchInput";
import { useRoute } from "@react-navigation/native";
import { View, Platform } from "react-native";

const SearchJobsInput = ({ width = "100%", marginRight = 20 }) => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const [searchKey, setSearchKey] = useState(
    decodeURI(route.params?.key || "")
  );

  const onChangeText = (newK) => {
    setSearchKey(newK);
  };

  const onSearchPress = useCallback(() => {
    linkTo(`/search?key=${searchKey}`);
  }, [searchKey]);

  const onSubmitEditing = useCallback(() => {
    linkTo(`/search?key=${searchKey}`);
  }, [searchKey]);

  return (
    <View style={{}}>
      <SearchInput
        placeholder="Що потрібно зробити?"
        value={searchKey}
        onSubmitEditing={onSubmitEditing}
        onChangeText={onChangeText}
        onSearchPress={onSearchPress}
      />
    </View>
  );
};

export default SearchJobsInput;
