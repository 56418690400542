import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function TimeMoneyIcon({
  mainColor = "#A0A0A0",
  sumbolColor = "#fff",
  mainSize = 14,
  symbolSize = 5,
}) {
  return (
    <View style={[styles.icon_box, { width: mainSize }]}>
      <Icon name="time-money" size={mainSize} color={mainColor} />
      <View style={[styles.symbol_box, { backgroundColor: mainColor }]}>
        <Icon name="hryvna" size={symbolSize} color={sumbolColor} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  icon_box: {
    position: "relative",
  },
  symbol_box: {
    width: 9,
    height: 9,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: -2,
    bottom: -2,
  },
});
