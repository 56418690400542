import { StyleSheet, View, Text, ScrollView } from "react-native";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import Logo from "../components/Logo";
import { Formik } from "formik";
import * as yup from "yup";
import { resetPasswordApi } from "../api/users.api";
import { useToast } from "react-native-toast-notifications";
import { useRoute } from "@react-navigation/native";
import { useCallback, useLayoutEffect } from "react";

const schema = yup.object().shape({
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    .required("Password confirm is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function ResetPassword({ navigation }) {
  navigation.setOptions({ title: 'Новий пароль', headerTintColor: 'white'})
  const route = useRoute();
  const token = decodeURI(route.params.token);
  const toast = useToast();

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Реєстрація', headerTintColor: 'white'})
  },[])


  const onSubmit = useCallback(
    async ({ password }) => {
      try {
        await resetPasswordApi({ password, token });
        toast.show(
          "Новий пароль збережено.Ви можете увійти, використовуючи Ваш новий пароль.",
          { placement: "top", type: "success" }
        );
        navigation.reset({ routes: [{ name: "SignIn" }] });
      } catch (e) {
        toast.show(e.response.data.message, {
          placement: "top",
          type: "danger",
        });
      }
    },
    [token, toast]
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={styles.container}>
        <View style={styles.logo_box}>
          <Logo height={55} />
        </View>
        <Text style={styles.form_title}>Створити новий пароль</Text>
        <Formik
          initialValues={{ password: "", passwordConfirmation: "" }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            values,
            errors,
            touched,
          }) => (
            <View style={styles.form}>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Пароль"
                  onChangeText={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  error={
                    errors.password && touched.password ? errors.password : null
                  }
                  secureTextEntry
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Підтвердити пароль"
                  onChangeText={handleChange("passwordConfirmation")}
                  onBlur={handleBlur("passwordConfirmation")}
                  value={values.passwordConfirmation}
                  error={
                    errors.passwordConfirmation && touched.passwordConfirmation
                      ? errors.passwordConfirmation
                      : null
                  }
                  secureTextEntry
                />
              </View>
              <CustomButton
                full
                text="Створити"
                onPress={handleSubmit}
                disabled={!(isValid && dirty)}
              />
            </View>
          )}
        </Formik>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
  },
  logo_box: {
    width: "100%",
    marginBottom: 95,
    paddingHorizontal: 5,
  },
  form_title: {
    fontSize: 28,
    lineHeight: 35,
    color: "#000000",
    marginBottom: 30,
  },
  form: {
    width: "100%",
    maxWidth: 305,
  },
  form_control: {
    marginBottom: 30,
  },
});
