import React, { useState, forwardRef } from "react";
import { StyleSheet, View, Text, Pressable, Platform } from "react-native";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// require('react-datepicker/dist/react-datepicker.css');
const WebDatePicker = ({ error, label, value, onChange }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Pressable style={styles.form_control} onPress={onClick}>
      {label && (
        <View>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      {error && (
        <View>
          <Text style={styles.text_error}>{error}</Text>
        </View>
      )}
      <Text style={styles.input}>{value}</Text>
    </Pressable>
  ));
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      customInput={<ExampleCustomInput />}
    />
  );
};
const styles = StyleSheet.create({
  form_control: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "600",
    marginBottom: 10,
    color: "#254D5C",
    marginLeft: 10,
  },
  input: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    lineHeight: 22,
    minHeight: 48,
    borderWidth: 1,
    borderRadius: 30,
  },
  text_error: {
    fontSize: 10,
    lineHeight: 10,
    color: "#EB5757",
    marginLeft: 10,
    marginBottom: 5,
  },
});

export default WebDatePicker;
