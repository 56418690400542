import React from "react";
import Skeleton from "./index";
import { View } from "react-native";

const ProfileItemSkeleton = () => {
  return (
    <View
      style={{
        borderRadius: 8,
        borderColor: "rgb(224, 224, 224)",
        borderWidth: 1,
        marginBottom: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"40%"}
          height={10}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          width={30}
          height={30}
          borderRadius={50}
        />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          width={57}
          height={57}
          borderRadius={50}
        />

        <View style={{ flex: 1, justifyContent: "center" }}>
          <Skeleton
            duration={1000}
            colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
            full={"100%"}
            height={20}
          />
        </View>
      </View>

      <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"30%"}
          height={12}
        />

        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"30%"}
          height={12}
        />
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"30%"}
          height={12}
        />
      </View>

      <View style={{ alignItems: "center" }}>
        <Skeleton
          duration={1000}
          colors={["#f1f1f4", "#fafafa", "#f1f1f4"]}
          full={"90%"}
          height={26}
          borderRadius={24}
        />
      </View>
    </View>
  );
};
export default ProfileItemSkeleton;
