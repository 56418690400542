import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";

export default NativeDatePicker = ({ error, label, value, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisible(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };

  const handleConfirm = (date) => {
    onChange(date);
    hideDatePicker();
  };

  return (
    <Pressable style={styles.form_control} onPress={showDatePicker}>
      {label && (
        <View>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      {error && (
        <View>
          <Text style={styles.text_error}>{error}</Text>
        </View>
      )}
      <View>
        <Text style={styles.input}>{moment(value).format("L")}</Text>
      </View>

      <DateTimePickerModal
        date={selectedDate}
        isVisible={datePickerVisible}
        mode="date"
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
      />
    </Pressable>

    //   <View style={{ height: 200, width: 300, backgroundColor: 'red'}}>
    //     <View
    //       style={{
    //         padding: 20,
    //         flex: 1,
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <Text style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
    //         {selectedDate ? selectedDate.toLocaleDateString() : 'No date selected'}
    //       </Text>
    //       <Button title="Select a date" onPress={showDatePicker} />
    //       <DateTimePickerModal
    //     date={selectedDate}
    //     isVisible={datePickerVisible}
    //     mode="date"
    //     onConfirm={handleConfirm}
    //     onCancel={hideDatePicker}
    //       />
    //     </View>
    //   </View>
  );
};

const styles = StyleSheet.create({
  form_control: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "600",
    marginBottom: 10,
    color: "#254D5C",
    marginLeft: 10,
  },
  input: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    lineHeight: 22,
    minHeight: 48,
    borderWidth: 1,
    borderRadius: 30,
  },
  text_error: {
    fontSize: 10,
    lineHeight: 10,
    color: "#EB5757",
    marginLeft: 10,
    marginBottom: 5,
  },
});
