import { Modal, View } from "react-native";
import { SafeAreaView } from "react-native";
import _ from "loadsh";
import Header from "../Header";
import RegionsSelect from "../RegionsSelect";

export default function RegionsModal({
  visible = false,
  selected = [],
  onPress,
  onClose = () => {},
}) {
  return (
    <Modal animationType={"slide"} transparent={false} visible={visible}>
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <Header onBackPress={onClose} title={"Регіони"} />
        <RegionsSelect selected={selected} onPress={onPress}></RegionsSelect>
      </SafeAreaView>
    </Modal>
  );
}
