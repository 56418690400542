import { StyleSheet, View, FlatList } from "react-native";
import { useState } from "react";
import CategoryItem from "../components/CategoryItem";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import CreateJobButtons from "../components/CreateJobButtons";
import SubCategoriesModal from "../components/modals/SubCategoriesModal";

export default function Categories({}) {
  const navigation = useNavigation();
  const categories = useSelector((state) => state.categories.list);

  const [subModalIsVisible, setSubModalIsVisible] = useState(false);
  const onClose = () => setSubModalIsVisible(false);
  const [parentCat, setParentCat] = useState({});

  return (
    <View style={styles.container}>
      <FlatList
        data={categories.filter((c) => !c.parentId)}
        renderItem={({ item }) => (
          <CategoryItem
            {...item}
            jobsCount={null}
            onPress={() => {
              setParentCat({ ...item });
              setSubModalIsVisible(true);
            }}
          />
        )}
        keyExtractor={(item) => item.id}
      />
      <CreateJobButtons />
      <SubCategoriesModal
        onClose={onClose}
        parentCat={parentCat}
        visible={subModalIsVisible}
        setSubModalIsVisible={setSubModalIsVisible}
        navigation={navigation}
      ></SubCategoriesModal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
    paddingBottom: 60,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
});
