import { StyleSheet, View, Text, ScrollView } from "react-native";
import Icon from "../components/icons/CustomIcon";
import CustomButton from "../components/CustomButton";

export default function DashboardPage({ navigation }) {
  return (
    <ScrollView style={styles.container}>
      <View style={styles.bordered_box}>
        <View style={styles.header}>
          <Text style={styles.gray_text}>Профіль</Text>
        </View>
        <View style={styles.body}>
          <Text style={styles.title}>Додайте фото у профіль</Text>
          <Text style={styles.descr}>
            Інші хочуть бачити з ким вони будуть співпрацювати
          </Text>
        </View>
        <View style={styles.footer}>
          <CustomButton text="Додати фото" full size="xs">
            <Text
              style={{ color: "#ffffff", marginLeft: 5, fontWeight: "bold" }}
            >
              +
            </Text>
          </CustomButton>
        </View>
      </View>

      <View style={styles.bordered_box}>
        <View style={styles.header}>
          <View style={styles.flex_row}>
            <Text style={[styles.gray_text, { marginRight: 5 }]}>
              Заявка на роботу
            </Text>
            <Icon name="user-target" size={11} color="#A0A0A0" />
          </View>
          <Text style={[styles.gray_text, { marginLeft: 20 }]}>
            Від: Валентин Криворучко
          </Text>
        </View>
        <View style={styles.body}>
          <Text style={styles.title}>Ви маєте нове запропоноване завдання</Text>
          <Text style={styles.descr}>
            Потрбіен сантехнік. Встановити трубопровід у новому домі
          </Text>
        </View>
        <View style={styles.footer}>
          <View style={[styles.btn_box, { marginRight: 9 }]}>
            <CustomButton text="Переглянути" full size="xs" />
          </View>
          <View style={[styles.btn_box, { marginLeft: 9 }]}>
            <CustomButton text="Написати в чат" full size="xs" />
          </View>
        </View>
      </View>

      <Text style={styles.section_title}>Повідомлення</Text>

      <View style={styles.bordered_box}>
        <View style={styles.header}>
          <View style={styles.flex_row}>
            <Text style={[styles.gray_text, { marginRight: 5 }]}>
              Заявка на роботу
            </Text>
            <Icon name="user-target" size={11} color="#A0A0A0" />
          </View>
          <Text style={[styles.gray_text, { marginLeft: 20 }]}>
            Від: Валентин Криворучко
          </Text>
        </View>
        <View style={styles.body}>
          <Text style={styles.title}>Ви маєте пропозицію на виконання</Text>
          <Text style={styles.descr}>
            Привіт! Бачу ти можеш мені допомогти з роботою....
          </Text>
        </View>
        <View style={styles.footer}>
          <View style={[styles.btn_box, { marginRight: 9 }]}>
            <CustomButton text="Відмовити" size="xs" full type="cancel" />
          </View>
          <View style={[styles.btn_box, { marginLeft: 9 }]}>
            <CustomButton
              text="Стати виконавцем"
              full
              size="xs"
              type="orange"
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 15,
    paddingBottom: 40,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingVertical: 10,
  },
  bordered_box: {
    backgroundColor: "#F9F9F9",
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 6,
    overflow: "hidden",
    marginBottom: 8,
  },
  header: {
    flexDirection: "row",
    paddingTop: 9,
    marginBottom: 18,
  },
  gray_text: {
    color: "#A0A0A0",
    fontSize: 12,
    lineHeight: 14,
  },
  title: {
    color: "#4C4C4C",
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "bold",
    marginBottom: 12,
  },
  descr: {
    color: "#4C4C4C",
    fontSize: 12,
    lineHeight: 14,
  },
  body: {
    marginBottom: 19,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 25,
    paddingBottom: 16,
  },
  section_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    marginTop: 3,
    paddingBottom: 3,
    fontWeight: "bold",
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    marginBottom: 12,
    marginHorizontal: 5,
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  btn_box: {
    flex: 1,
  },
});
