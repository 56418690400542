import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoriesApi } from "../../api/categories.api";

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const { data } = await categoriesApi();
    return data;
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: { list: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      //  state.loading = true
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.list = action.payload;
      //   state.loading = false
    });
    builder.addCase(fetchCategories.rejected, (state) => {
      // state.loading = false
    });
  },
});

export default categoriesSlice.reducer;
