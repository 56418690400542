import { StyleSheet, View, Pressable } from "react-native";

export default function BurgerIcon({ onPress }) {
  return (
    <Pressable onPress={onPress}>
      <View style={styles.burger_box}>
        <View style={styles.line}></View>
        <View style={[styles.line, styles.midle_line]}></View>
        <View style={styles.line}></View>
      </View>
      <View style={styles.burger_bg}></View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  burger_box: {
    width: 34,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 18,
    height: 3,
    backgroundColor: "white",
  },
  midle_line: {
    marginVertical: 3,
  },
  burger_bg: {
    width: 34,
    height: 34,
    borderRadius: 17,
    position: "absolute",
    left: 2,
    top: 1,
    zIndex: -1,
  },
});
