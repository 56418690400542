import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import PhoneInput from "../components/PhoneInput";
import Logo from "../components/Logo";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { signInAction } from "../store/user";
import { useToast } from "react-native-toast-notifications";
import { useState } from "react";

const schema = yup.object().shape({
  phone: yup
    .string()
    .min(9, "Телефон є обовʼязковим")
    .required("Телефон є обовʼязковим"),
  password: yup.string().required("Field is required"),
});

export default function SignIn({ navigation, route }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const toast = useToast();
  const { params: { page, params } = { page: null } } = route;

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={styles.container}>
        <View style={styles.logo_box}>
          <Logo height={55} />
        </View>
        <Text style={styles.form_title}>Увійти</Text>

        {error && (
          <Text style={{ marginBottom: 10, color: "red" }}>
            {"Телефон або пароль недійсні"}
          </Text>
        )}
        <Formik
          initialValues={{ phone: "+38(0", password: "" }}
          validationSchema={schema}
          onSubmit={async (values) => {
            const { error } = await dispatch(
              signInAction({ ...values, phone: `+380${values.phone}` })
            );
            setError(null);
            if (error) {
              setError("Телефон або пароль недійсні");
            } else {
              toast.show("Ви успішно увійшли", {
                placement: "top",
                type: "success",
              });
              if (page) {
                navigation.pop();
                // navigation.push(page, params);
              } else {
                navigation.reset({ routes: [{ name: "Feed" }] });
              }
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            values,
            errors,
            touched,
          }) => (
            <View style={styles.form}>
              <View style={styles.form_control}>
                <PhoneInput
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={values.phone}
                  error={errors.phone && touched.phone ? errors.phone : null}
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Пароль"
                  onChangeText={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  secureTextEntry
                  error={
                    errors.password && touched.password ? errors.password : null
                  }
                />
              </View>
              <TouchableOpacity
                onPress={() => navigation.push("ForgotPassword")}
              >
                <Text style={styles.pass_link}>Забули пароль?</Text>
              </TouchableOpacity>
              <View style={styles.btn_box}>
                <CustomButton
                  text="Вхід"
                  full
                  onPress={handleSubmit}
                  disabled={!(isValid && dirty)}
                />
              </View>
              {/* <View style={styles.btn_box}>
                <CustomButton
                  full
                  size="lg"
                  text="Зареєструватися"
                  onPress={() => navigation.push('SignUp')}
                />
              </View> */}
            </View>
          )}
        </Formik>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    backgroundColor: "#ffffff",
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  logo_box: {
    width: "100%",
    marginBottom: 95,
    paddingHorizontal: 5,
  },
  form_title: {
    fontSize: 28,
    lineHeight: 35,
    color: "#000000",
    marginBottom: 30,
  },
  pass_link: {
    fontSize: 16,
    lineHeight: 22,
    color: "#000000",
    textAlign: "center",
    textDecorationLine: "underline",
    marginBottom: 30,
    marginTop: 14,
  },
  btn_box: {
    width: "100%",
    marginBottom: 10,
    // maxWidth: 305
  },
  form: {
    width: "100%",
    // maxWidth: 305
  },
  form_control: {
    marginBottom: 30,
  },
});
