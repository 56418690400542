import { StyleSheet, Text, View } from "react-native";
import RadioGroup from "react-native-radio-buttons-group";
import { find } from "lodash";

export default function CustomRadioButton({
  label,
  value,
  options,
  error,
  onChange,
  layout = "row",
}) {
  const test = options.map((d) => ({ ...d, selected: d.value === value }));
  return (
    <View style={styles.custominput}>
      {label && <Text style={styles.text_name}>{label}</Text>}
      <RadioGroup
        color="#888"
        layout={layout}
        radioButtons={test}
        onPress={(data) => {
          onChange(find(data, { selected: true })?.value);
        }}
      />
      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },

  textarea: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
    textAlignVertical: "top",
  },

  input: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    height: 45,
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
  },

  shadow: {
    position: "absolute",
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: 0,
    zIndex: -1,
  },

  text_name: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "600",
    marginBottom: 10,
    color: "#254D5C",
    marginLeft: 10,
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
  },
});
