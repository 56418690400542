import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {};
export const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    endLoading(state) {
      state.loading = false;
    },
  },
  extraReducers: {},
});

export const { startLoading, endLoading } = apiSlice.actions;
export default apiSlice.reducer;
