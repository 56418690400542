import { View, Text, FlatList } from "react-native";
import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { profileSelector } from "../store/user/user.selector";
import UserListItem from "../components/UserListItem";
import { RefreshControl } from "react-native";
import RoundButton from "../components/RoundButton";
import CustomButton from "../components/CustomButton";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { useJobSearch } from "../hooks";
import JobItemSkeleton from "../components/Skeletons/JobItemSkeleton";
import CreateJobButtons from "../components/CreateJobButtons";
import { useRemoveJob, useToggleFavorite } from "../hooks";
import { useEffect } from "react";
import { useIsFocused } from "@react-navigation/native";

const ListEmptyComponent = () => {
  return (
    <View>
      <Text
        style={{
          textAlign: "center",
          fontWeight: "700",
          fontSize: 16,
          marginVertical: 20,
        }}
      >
        Ви ще не створили пропозицію
      </Text>
    </View>
  );
};

export default function MyJobsPage({ navigation }) {
  const { id } = useSelector(profileSelector);
  const deleteMutation = useRemoveJob();
  const { onFavorite, isFavorite } = useToggleFavorite();
  const [refetchToggle, setRefetchToggle] = useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const options = { userId: id, page: 1, limit: 5, refetchToggle };
  const isFocused = useIsFocused();

  const {
    data,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching,
    refetch,
  } = useJobSearch({ userId: id, page: 1, limit: 5, refetchToggle, isFocused });

  const [refreshing, setRefreshing] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    visible: false,
    payload: null,
  });

  useEffect(() => {
    setRefetchToggle((Math.random() + 1).toString(36).substring(7));
  }, []);
  const list = (data?.pages || []).map((page) => page.list).flat();

  const onDelete = useCallback((payload) => {
    setConfirmModal({ visible: true, payload });
  }, []);

  const onConfirm = useCallback(async () => {
    await deleteMutation.mutateAsync(confirmModal.payload);
    setConfirmModal({ visible: false, payload: {} });
    setRefetchToggle((Math.random() + 1).toString(36).substring(7));
  }, [confirmModal, deleteMutation]);

  const onCancel = useCallback(() => {
    setConfirmModal({ visible: false, payload: {} });
  }, []);

  const onEndReached = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);

  const onRefresh = useCallback(async () => {}, []);

  return (
    <View
      style={{
        flex: 1,
        width: "100%",
        maxWidth: 600,
        paddingBottom: 60,
        alignSelf: "center",
      }}
    >
      <FlatList
        ListEmptyComponent={
          isFetching || isRefetching ? (
            <>
              <JobItemSkeleton />
              <JobItemSkeleton />
              <JobItemSkeleton />
            </>
          ) : (
            <ListEmptyComponent />
          )
        }
        data={list}
        // extraData={list}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        renderItem={({ item, index }) => (
          <UserListItem
            key={index}
            {...item}
            bottomSlot={
              <View style={{ marginTop: 10 }}>
                <CustomButton
                  size="xs"
                  full
                  text="Редагувати"
                  onPress={() => {
                    navigation.push("EditJob", { id: item.id });
                  }}
                />
              </View>
            }
            rightTopSlot={
              <>
                <View style={{ marginLeft: 5 }}>
                  <RoundButton
                    onPress={() => onDelete(item.id)}
                    iconName={"trash"}
                    backgroundColor={"red"}
                    iconColor={"white"}
                  />
                </View>
              </>
            }
            favorite={isFavorite(item.id)}
            avatarUrl={item.fileName}
            onFavorite={() => onFavorite(item.id)}
            onPress={() => {
              navigation.push("JobDetails", { alias: item.alias });
            }}
          />
        )}
        keyExtractor={(item) => item.id}
        onEndReachedThreshold={0.8}
        onEndReached={onEndReached}
        ListFooterComponent={
          isFetching ? (
            <>
              <JobItemSkeleton />
              <JobItemSkeleton />
              <JobItemSkeleton />
            </>
          ) : (
            <></>
          )
        }
      />
      <ConfirmationModal
        visible={confirmModal.visible}
        title={"Ви впевнені, що хочете видалити це оголошення?"}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <CreateJobButtons />
    </View>
  );
}
