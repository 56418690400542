import { createNativeStackNavigator } from "@react-navigation/native-stack";
import MessagesPage from "../pages/chat/Messages";
import PrivateChat from "../pages/chat/PrivateChat";
import OpenDrawerButton from "../components/OpenDrawerButton";
import BackButton from "../components/BackButton";

const Stack = createNativeStackNavigator();

const commonDrawerPageOptions = {
  headerLeft: () => <OpenDrawerButton />,
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

const commonTitleStyle = {
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};
const ChatStack = ({ route }) => {
  return (
    <Stack.Navigator initialParams={route.params}>
      <Stack.Screen
        name="MessagesPage"
        component={MessagesPage}
        options={{ title: "Повідомлення", ...commonDrawerPageOptions }}
      />
      <Stack.Screen
        name="PrivateChat"
        component={PrivateChat}
        options={{
          ...commonTitleStyle,
          title: "",
          headerLeft: () => <BackButton backLinkTo={"/cabinet/chat"} />,
        }}
      />
    </Stack.Navigator>
  );
};

export default ChatStack;
