import { jobsApi, deleteJob } from "../api/jobs.api";
import { usersApi, deleteProfileApi } from "../api/users.api";
import { leaveFeedbackApi } from "../api/feedback.api";
import { feedbackApi } from "../api/feedback.api";
import { useToast } from "react-native-toast-notifications";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useInfiniteQuery } from "react-query";
import { useNavigation } from "@react-navigation/native";
import { profileSelector } from "../store/user/user.selector";
import { lastMessagesApi } from "../api/chat.api";
import { toggleFavoriteAction } from "../store/user";
import { useCallback } from "react";
import { getFavoriteItemsApi } from "../api/users.api";
import { useEffect } from "react";
import { logOut } from "../store/user";

export const useJobSearch = (filters) => {
  return useInfiniteQuery(
    ["jobs", filters],
    ({ queryKey, pageParam }) => jobsApi({ ...queryKey[1], page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
      },
      // refetchPage: (page, index, allPages) => true
    }
  );
};

export const useLastMessages = (filters) => {
  return useInfiniteQuery(
    ["messages", filters],
    ({ queryKey, pageParam }) =>
      lastMessagesApi({ ...queryKey[1], page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
      },
      select: (data) => (data?.pages || []).map((page) => page.messages).flat(),
    }
  );
};

export const useFavoriteJobs = (filters = {}) => {
  const { favorite } = useSelector(profileSelector);
  const hook = useInfiniteQuery(
    ["jobs", filters, favorite],
    ({ queryKey, pageParam }) =>
      getFavoriteItemsApi({ ...queryKey[1], page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
      },
      refetchPage: (page, index, allPages) => true,
    }
  );

  useEffect(() => {}, [favorite]);

  return hook;
};

export const useUsersSearch = (filters) => {
  return useInfiniteQuery(
    ["users", filters],
    ({ queryKey, pageParam }) => usersApi({ ...queryKey[1], page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
      },
    }
  );
};

export const useFeedbacks = (options) => {
  return useInfiniteQuery(
    ["feedbacks", options],
    ({ queryKey, pageParam }) =>
      feedbackApi({ ...queryKey[1], page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
      },
      select: (data) => (data?.pages || []).map((page) => page.list).flat(),
    }
  );
};

export const useIsAuthenticated = () => {
  return useSelector((state) => state.user.access_token);
};

export const useToggleFavorite = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { favorite, access_token } = useSelector(profileSelector);

  const isFavorite = useCallback(
    (id) => {
      return favorite.includes(id);
    },
    [favorite]
  );

  const onFavorite = useCallback((id) => {
    if (access_token) {
      dispatch(toggleFavoriteAction(id));
    } else {
      navigation.push("SignIn");
    }
  }, []);

  return { favorite, onFavorite, isFavorite };
};

export const useAddFeedback = () => {
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: (values) => leaveFeedbackApi(values),
    onError: () => {
      toast.show("Щось пішло не так", { placement: "top", type: "danger" });
    },
    onSuccess: () => {
      toast.show("Ви успішно залишили відгук для", {
        placement: "top",
        type: "success",
      });
    },
  });

  return mutation;
};

export const useRemoveJob = () => {
  const toast = useToast();
  const navigation = useNavigation();
  const mutation = useMutation({
    mutationFn: (id) => deleteJob(id),
    onError: () => {
      toast.show("Щось пішло не так", { placement: "top", type: "danger" });
    },
    onSuccess: () => {
      toast.show("Оголошення було успішно видалено", {
        placement: "top",
        type: "success",
      });
      navigation.reset({ routes: [{ name: "MyJobsPage" }] });
    },
  });

  return mutation;
};

export const useRemoveUser = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigation = useNavigation();
  const mutation = useMutation({
    mutationFn: (id) => deleteProfileApi(id),
    onError: () => {
      toast.show("Щось пішло не так", { placement: "top", type: "danger" });
    },
    onSuccess: () => {
      toast.show("Обліковий запис було успішно видалено", {
        placement: "top",
        type: "success",
      });
      dispatch(logOut());
      navigation.reset({
        routes: [{ name: "Feed", screen: "Categories" }],
      });
    },
  });

  return mutation;
};