import { StyleSheet, Image, View } from "react-native";

export default function Logo({ height = "100%", width = "100%" }) {
  return (
    <Image
      style={[styles.logo, { height, width }]}
      source={require("../assets/logo.png")}
    />
  );
}

const styles = StyleSheet.create({
  logo: {
    resizeMode: "contain",
  },
});
