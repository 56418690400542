import { StyleSheet, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";
import { useNavigation } from "@react-navigation/native";

export default function FilterButton({ onPress }) {
  const navigation = useNavigation();
  return (
    <Pressable onPress={onPress} style={{ padding: 10 }}>
      <Icon name="filter" color="white" size={20} />
    </Pressable>
  );
}
const styles = StyleSheet.create({});
