import React from "react";
import { Modal } from "react-native";
import { SafeAreaView } from "react-native";
import VerificationCode from "../components/VerificationCode";
import Header from "../components/Header";

export default function SignUpCodeModal({
  error = null,
  visible = false,
  phone,
  onClose = () => {},
  onVerify = () => {},
}) {
  return (
    <Modal animationType={"slide"} transparent={false} visible={visible}>
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <Header onBackPress={onClose} title={"Верифікація коду"} />
        <VerificationCode phone={phone} error={error} onVerify={onVerify} />
      </SafeAreaView>
    </Modal>
  );
}
