import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Image,
  ScrollView,
} from "react-native";
import RatingDetailed from "../../components/RatingDetailed";
import CustomButton from "../../components/CustomButton";
import GuardedButton from "../../components/GuardedButton";
import Icon from "../../components/icons/CustomIcon";
import TimeMoneyIcon from "../../components/icons/TimeMoneyIcon";
import { useState, useCallback, useEffect } from "react";
import RenderHtml from "react-native-render-html";
import LeaveFeedbackModal from "../../components/modals/LeaveFeedbackModal";
import FeedbacksSlider from "./components/FeedbacksSlider";
import GallerySlider from "./components/GallerySlider";
import { useQuery } from "react-query";
import { userByIdApi } from "../../api/users.api";
import { jobsApi } from "../../api/jobs.api";
import ProfileSkeleton from "../../components/Skeletons/ProfileSkeleton";
import { useAddFeedback, useIsAuthenticated, useFeedbacks } from "../../hooks";
import { useLinkTo } from "@react-navigation/native";
import FeedbacksModal from "../../components/modals/FeedbacksModal";

export default function ProfileDetails({
  route: {
    params: { id },
  },
  navigation,
}) {
  const isAuthenticated = useIsAuthenticated();
  const linkTo = useLinkTo();
  const addFeedback = useAddFeedback();
  // const { width } = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const [showFeedbacks, setShowFeedbacks] = useState(false);

  const { data: profile = {}, isFetching: isProfileFetching } = useQuery(
    ["profile", id],
    () => userByIdApi(id)
  );
  const query = { limit: 3, page: 1, userId: id };
  const {
    data: feedback_list,
    isFetching: isFeedbacksFetching,
    refetch,
  } = useFeedbacks(query);
  const {
    // data: { list: jobsList = [] } = { list: [] },
    isFetching: isJobsFetching,
  } = useQuery(["profile_jobs", id], () => jobsApi(query));

  useEffect(() => {
    navigation.setOptions({ title: `${profile.firstName}` });
  }, [profile]);

  // const feedback_list = [];
  const onShowFeedbackModal = useCallback(async () => {
    if (isAuthenticated) {
      setVisible(true);
    } else {
      linkTo("/cabinet/auth/sign-in");
    }
  }, [linkTo, isAuthenticated]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        await addFeedback.mutateAsync({ ...values, id: Number(id) });
      } catch (e) {
        refetch();
      }
    },
    [id, addFeedback, linkTo]
  );

  const showSkeleton =
    isProfileFetching || isFeedbacksFetching || isJobsFetching;

  return (
    <ScrollView style={{ flex: 1, backgroundColor: "#ffffff" }}>
      {showSkeleton ? (
        <View style={styles.container}>
          <View style={styles.profile}>
            <ProfileSkeleton />
          </View>
        </View>
      ) : (
        <>
          <View style={styles.container}>
            <View style={styles.profile}>
              <View style={styles.main}>
                <View style={styles.info}>
                  <View style={[styles.main_item]}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={styles.avatar}>
                        <View style={styles.avatar_wrap}>
                          {profile.avatarUrl ? (
                            <Image
                              source={{ uri: profile.avatarUrl }}
                              style={styles.avatar_img}
                            />
                          ) : (
                            <Icon name="user" color="#E0E0E0" size={30} />
                          )}
                        </View>
                        {/* <View style={styles.check_wrapp}>
                        <Icon name='check' color='#fff' size={7}/>
                      </View> */}
                      </View>
                      <View style={{ flexShrink: 1 }}>
                        <Text style={styles.name}>
                          {profile.firstName} {profile.secondName}{" "}
                          {profile.nickName}
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Icon name="map-pin" size={18} color="#209CFF" />
                          <Pressable style={{ marginLeft: 5 }}>
                            <Text style={styles.location}>
                              м.
                              {(profile.regions || [])
                                .map((r) => r.name)
                                .join(",")}
                            </Text>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={[styles.main_item, { flexDirection: "row" }]}>
                    <View style={{ marginRight: 40 }}>
                      <Text style={[styles.gray_text, { marginBottom: 5 }]}>
                        Робіт запропановано:
                      </Text>
                      <View style={styles.flex_row}>
                        <Icon name="user-target" size={17} color="#A0A0A0" />
                        <Text style={styles.green_text}>
                          {profile.jobs_done} робіт
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.gray_text, { marginBottom: 5 }]}>
                        Середній рівень оплати:
                      </Text>
                      <View style={styles.flex_row}>
                        <TimeMoneyIcon />
                        <Text style={styles.green_text}>
                          {profile.price} грн
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={[styles.main_item, styles.flex_row]}>
                    <Text style={[styles.gray_text, { marginRight: 50 }]}>
                      Рейтинг виконавця:
                    </Text>
                    <View style={[styles.rating, styles.flex_row]}>
                      <Icon name="star-full" size={20} color="#F2994A" />
                      <Icon name="star-full" size={20} color="#F2994A" />
                      <Icon name="star-full" size={20} color="#F2994A" />
                      <Icon name="star-full" size={20} color="#F2994A" />
                      <Icon name="star-full" size={20} color="#F2994A" />
                      <Text style={styles.rating_score}>{profile.rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 10 }}>
                  <GuardedButton
                    full
                    text="Написати в чаті"
                    onPress={() => {
                      linkTo(`/profile/${profile.userId}/chat`);
                    }}
                  />
                </View>

                <View style={{ marginTop: 17 }}>
                  <Text style={styles.descr_title}>{profile.title}</Text>
                  <RenderHtml
                    style={styles.descr_text}
                    contentWidth={0}
                    source={{ html: profile.description || "<p></p>" }}
                  />
                </View>
              </View>

              {profile?.gallery?.length && (
                <GallerySlider list={profile.gallery} />
              )}
              {/* {jobsList.length && <OpenedJobs list={jobsList} />} */}
              <Text style={styles.section_title}>Відгуки</Text>
              {profile && (
                <RatingDetailed
                  {...profile}
                  feedbacksAmount={feedback_list?.length}
                />
              )}
              {feedback_list?.length > 0 && (
                <FeedbacksSlider
                  list={feedback_list}
                  onShowFeedbacks={() => {
                    setShowFeedbacks(true);
                  }}
                />
              )}
            </View>
            {profile.can_leave_feedback && (
              <View style={[styles.btn_box, styles.slider_btn]}>
                <CustomButton
                  text="Додати відгук"
                  size="sm"
                  full
                  onPress={onShowFeedbackModal}
                />
              </View>
            )}
          </View>
          <LeaveFeedbackModal
            onSubmit={onSubmit}
            visible={visible}
            onClose={() => {
              setVisible(false);
            }}
          ></LeaveFeedbackModal>

          <FeedbacksModal
            visible={showFeedbacks}
            id={id}
            onClose={() => {
              setShowFeedbacks(false);
            }}
          ></FeedbacksModal>
        </>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingBottom: 50,
  },
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  main: {
    backgroundColor: "#F9F9F9",
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  avatar: {
    position: "relative",
    marginRight: 15,
  },
  avatar_wrap: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderWidth: 2,
    borderColor: "#E0E0E0",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  avatar_img: {
    width: "110%",
    height: "110%",
    resizeMode: "cover",
  },
  check_wrapp: {
    position: "absolute",
    top: 2,
    right: -4,
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: "#6FCF97",
  },
  main_item: {
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    paddingVertical: 18,
  },
  name: {
    fontSize: 18,
    lineHeight: 29,
    color: "#4C4C4C",
    fontWeight: "bold",
    paddingRight: 20,
    marginBottom: 16,
  },
  location: {
    color: "#209CFF",
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "bold",
  },
  gray_text: {
    fontSize: 12,
    lineHeight: 15,
    color: "#A0A0A0",
  },
  green_text: {
    fontSize: 18,
    lineHeight: 22,
    color: "#27AE60",
    fontWeight: "bold",
    marginLeft: 5,
  },
  rating_score: {
    fontSize: 26,
    lineHeight: 30,
    color: "#F2994A",
    fontWeight: "bold",
    marginLeft: 5,
  },
  descr_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    fontWeight: "bold",
  },
  descr_text: {
    fontSize: 14,
    lineHeight: 19,
    color: "#4C4C4C",
    marginTop: 20,
  },
  section_title: {
    fontSize: 16,
    lineHeight: 26,
    color: "#4C4C4C",
    marginTop: 20,
    paddingBottom: 3,
    fontWeight: "bold",
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    marginBottom: 15,
    marginHorizontal: 5,
  },
  galery: {
    height: 225,
    width: "100%",
  },
  job_slider: {
    minHeight: 206,
    width: "100%",
  },
  review_slider: {
    flex: 1,
    // minHeight: 380,
    width: "100%",
  },
  blue_btn: {
    width: 32,
    height: 40,
    backgroundColor: "#209CFF",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  btn_box: {
    maxWidth: "73%",
    width: "100%",
    alignSelf: "center",
    marginTop: 17,
  },
  slider_btn: {
    position: "absolute",
    alignSelf: "center",
    bottom: 3,
  },
});
