import { StyleSheet, View, ScrollView } from "react-native";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { Formik } from "formik";
import * as yup from "yup";
import RatingInput from "../components/RatingInput";

const schema = yup.object().shape({
  message: yup.string().required("Field is required"),
});

export default function LeaveFeedback({ navigation }) {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Formik
        initialValues={{
          politeness: 1,
          punctuality: 1,
          quality: 1,
          message: "",
        }}
        validationSchema={schema}
        onSubmit={async (values) => {}}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          dirty,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <View style={styles.form}>
            <View style={styles.form_control}>
              <RatingInput
                label="Якість виконаної роботи"
                onPress={(r) => {
                  setFieldValue("quality", r);
                }}
              ></RatingInput>
            </View>

            <View style={styles.form_control}>
              <RatingInput
                label="Пунктуальність"
                onPress={(r) => {
                  setFieldValue("punctuality", r);
                }}
              ></RatingInput>
            </View>

            <View style={styles.form_control}>
              <RatingInput
                label="Вічливість"
                onPress={(r) => {
                  setFieldValue("politeness", r);
                }}
              ></RatingInput>
            </View>

            <View style={styles.form_control}>
              <CustomInput
                label="Відгук"
                placeholder="Опишіть, які саме послуги замовляли"
                onChangeText={handleChange("message")}
                onBlur={handleBlur("message")}
                value={values.message}
                error={
                  errors.message && touched.message ? errors.message : null
                }
                multiline
                numberOfLines={8}
                borderColor="#209CFF"
              />
            </View>

            <View style={styles.btn_box}>
              <CustomButton
                text="Розмістити відгук"
                full
                onPress={handleSubmit}
              />
            </View>
          </View>
        )}
      </Formik>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 15,
    paddingBottom: 40,
    paddingTop: 40,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
  },
  btn_box: {
    alignSelf: "center",
    width: "100%",
    marginTop: 25,
  },
  form: {
    width: "100%",
  },
  form_control: {
    marginBottom: 20,
  },
});
