import { StyleSheet, TouchableOpacity } from "react-native";
import Icon from "./icons/CustomIcon";
export default function RoundButton({
  backgroundColor = "white",
  iconName = "star-empty",
  iconColor = "red",
  onPress = () => {},
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.star_box, { backgroundColor }]}
    >
      <Icon name={iconName} color={iconColor} size={14} />
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  star_box: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
