import {
  StyleSheet,
  View,
  FlatList,
  SafeAreaView,
  ScrollView,
} from "react-native";
import ReviewItem from "../components/ReviewItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUserFeedbacks } from "../store/users";
import { userSelector } from "../store/users/users.selector";
import CustomButton from "./../components/CustomButton";

export default function ProfileFeedbacks({
  navigation,
  route: {
    params: { id },
  },
}) {
  const dispatch = useDispatch();
  const { feedbacks } = useSelector(userSelector);
  const feedbacksList = feedbacks?.list || [];

  useEffect(() => {
    dispatch(fetchUserFeedbacks({ userId: id }));
  }, [id]);

  const FooterButton = () => {
    return (
      <View style={[styles.btn_box]}>
        <CustomButton
          text="Додати відгук"
          size="sm"
          full
          onPress={() => {
            navigation.push("LeaveFeedback");
          }}
        />
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#ffffff" }}>
      <ScrollView>
        <View style={styles.container}>
          <FlatList
            data={feedbacksList}
            renderItem={({ item }) => (
              <View style={styles.feedback}>
                <ReviewItem {...item} />
              </View>
            )}
            keyExtractor={(item, i) => i}
            ListFooterComponent={FooterButton}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingBottom: 50,
    marginTop: 15,
  },
  feedback: {
    marginBottom: 15,
  },
});
