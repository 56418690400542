import { Pressable } from "react-native";
import { Header as _Header } from "@react-navigation/elements";
import Icon from "../components/icons/CustomIcon";

const styles = {
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

const Header = ({ modal = true, title = "", onBackPress }) => {
  return (
    <_Header
      title={title}
      {...styles}
      layout={{ width: 500 }}
      modal={modal}
      headerLeft={() => (
        <Pressable onPress={onBackPress} style={{ padding: 10 }}>
          <Icon name="chevron-left" color="white" size={16} />
        </Pressable>
      )}
    ></_Header>
  );
};

export default Header;
