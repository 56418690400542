import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  signInApi,
  signUpApi,
  getCurrentUserApi,
  updateProfileApi,
  getFavoriteApi,
  toggleFavoriteApi,
  getFavoriteItemsApi,
} from "../../api/users.api";

import { addJobGaleryApi } from "../../api/jobs.api";
import { myJobsListApi, updateJobApi, getJobByIds } from "../../api/jobs.api";
import { map, xor } from "loadsh";

export const signInAction = createAsyncThunk(
  "user/signIn",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await signInApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavoriteListAction = createAsyncThunk(
  "user/getFavoriteList",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getFavoriteItemsApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchJobForEdit = createAsyncThunk(
  "user/fetchJobForEdit",
  async (id) => {
    const { data } = await getJobByIds(id);
    return data;
  }
);

export const updateJob = createAsyncThunk("user/updateJob", async (body) => {
  const { data } = await updateJobApi(body);
  return data;
});

export const addJobGallery = createAsyncThunk(
  "user/updateJobGallery",
  async ({ id, image }) => {
    const { data } = await addJobGaleryApi(id, image);
    return data;
  }
);

export const nextFavoriteListPage = createAsyncThunk(
  "user/nextFavoritePage",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { page = 1, pages = 0, limit } = getState().user.favoriteJobs;
      const nextPage = page + 1;
      const { data } = await getFavoriteItemsApi({ page: nextPage, limit });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavoriteAction = createAsyncThunk(
  "user/getFavorite",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getFavoriteApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleFavoriteAction = createAsyncThunk(
  "user/toggleFavoriteAction",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await toggleFavoriteApi(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const nextMyJobsListPage = createAsyncThunk(
  "user/nextMyJobsListPage",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { page = 1, pages = 0, limit } = getState().user.jobs;
      const nextPage = page + 1;
      const { data } = await myJobsListApi({ page: nextPage, limit });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const myJobsListAction = createAsyncThunk(
  "user/myJobsListAction",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await myJobsListApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfileAction = createAsyncThunk(
  "user/getProfile",
  async (_y, { rejectWithValue }) => {
    try {
      const { data } = await getCurrentUserApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfileAction = createAsyncThunk(
  "user/updateProfile",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await updateProfileApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signUpAction = createAsyncThunk(
  "user/signUp",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await signUpApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  id: null,
  nickName: "",
  firstName: "",
  secondName: "",
  thirdName: "",
  title: "",
  description: "",
  rating: 0,
  email: "",
  phone: "",
  price: 6,
  priceType: "fixed",
  avatarUrl: "",
  verified: true,
  verificationCode: 7241,
  createdAt: null,
  gallery: [],
  regions: [],
  categories: [],
  originalAvatarUrl: null,
  favoriteJobs: [],
  favorite: [],
  editJob: {
    id: 0,
    parentId: null,
    priceType: "hourly",
    executionDate: "2022-08-16T13:59:02.000Z",
    title: "",
    taskType: "find",
    price: 0,
    description: "",
    gallery: [],
    regions: [],
    categories: [],
  },
  jobs: {
    count: 0,
    list: [],
    page: 1,
    pages: 0,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeCategories: (state, action) => {
      state.categories = xor(state.categories, [action.payload]);
    },
    changeRegions: (state, action) => {
      state.regions = xor(state.regions, [action.payload]);
    },
    changeJobCategories: (state, action) => {
      state.editJob.categories = xor(state.editJob.categories, [
        action.payload,
      ]);
    },
    changeJobRegions: (state, action) => {
      state.editJob.regions = xor(state.editJob.regions, [action.payload]);
    },
    resetFavoriteList(state) {
      state.favoriteJobs = {
        count: 0,
        list: [],
        page: 1,
        pages: 0,
        limit: 3,
      };
    },
    resetMyJobs(state) {
      state.jobs = {
        count: 0,
        list: [],
        page: 1,
        pages: 0,
        limit: 3,
      };
    },
    logOut: (state) => {
      state.access_token = null;
      state.favorite = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(myJobsListAction.fulfilled, (state, action) => {
      state.jobs = action.payload;
      //   state.loading = false
    });
    builder.addCase(getFavoriteListAction.fulfilled, (state, action) => {
      state.favoriteJobs = action.payload;
      //   state.loading = false
    });
    builder.addCase(updateJob.fulfilled, (state, action) => {
      state.editJob = {
        ...action.payload,
        regions: action.payload.regions.map((r) => r.id),
        categories: action.payload.categories.map((r) => r.id),
      };

      const prevList = state.jobs.list;
      state.jobs.list = prevList.map((j) => {
        if (j.id === action.payload.id) {
          return action.payload;
        } else {
          return j;
        }
        return j;
      });
    });
    builder.addCase(fetchJobForEdit.fulfilled, (state, action) => {
      state.editJob = {
        ...action.payload,
        regions: action.payload.regions.map((r) => r.id),
        categories: action.payload.categories.map((r) => r.id),
      };
    });
    builder.addCase(nextFavoriteListPage.fulfilled, (state, action) => {
      state.favoriteJobs = {
        ...state.favoriteJobs,
        ...action.payload,
        list: [...state.favoriteJobs.list, ...action.payload.list],
      };
    });
    builder.addCase(nextMyJobsListPage.fulfilled, (state, action) => {
      state.jobs = {
        ...state.jobs,
        ...action.payload,
        list: [...state.jobs.list, ...action.payload.list],
      };
    });
    builder.addCase(getFavoriteListAction.rejected, (state) => {
      // state.loading = false
    }),
      builder.addCase(toggleFavoriteAction.pending, (state) => {
        //  state.loading = true
      });
    builder.addCase(toggleFavoriteAction.fulfilled, (state, action) => {
      state.favorite = action.payload;
      //   state.loading = false
    });

    builder.addCase(getFavoriteAction.fulfilled, (state, action) => {
      state.favorite = action.payload;
      //   state.loading = false
    });
    builder.addCase(getFavoriteAction.rejected, (state) => {
      // state.loading = false
    }),
      builder.addCase(signInAction.pending, (state) => {
        //  state.loading = true
      });
    builder.addCase(signInAction.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      //   state.loading = false
    });
    builder.addCase(signInAction.rejected, (state) => {
      // state.loading = false
    }),
      builder.addCase(getProfileAction.pending, (state) => {
        //  state.loading = true
      });
    builder.addCase(getProfileAction.fulfilled, (state, action) => {
      state.firstName = action.payload.firstName;
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.nickName = action.payload.nickName;
      state.firstName = action.payload.firstName;
      state.secondName = action.payload.secondName;
      state.thirdName = action.payload.thirdName;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.description = action.payload.description;
      state.price = action.payload.price;
      state.categories = map(action.payload.categories, "id");
      state.regions = map(action.payload.regions, "id");
      state.priceType = action.payload.priceType;
      state.avatarUrl = action.payload.avatarUrl;
      state.gallery = action.payload.gallery;

      //   state.loading = false
    }),
      builder.addCase(addJobGallery.fulfilled, (state, action) => {
        state.editJob.gallery = state.editJob.gallery = [
          ...state.editJob.gallery,
          action.payload,
        ];
      }),
      builder.addCase(getProfileAction.rejected, (state) => {
        // state.loading = false
      }),
      builder.addCase(updateProfileAction.pending, (state) => {
        //  state.loading = true
      });
    builder.addCase(updateProfileAction.fulfilled, (state, action) => {
      state.firstName = action.payload.firstName;

      state.title = action.payload.title;
      state.nickName = action.payload.nickName;
      state.firstName = action.payload.firstName;
      state.secondName = action.payload.secondName;
      state.thirdName = action.payload.thirdName;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.description = action.payload.description;
      state.price = action.payload.price;
      state.categories = map(action.payload.categories, "id");
      state.regions = map(action.payload.regions, "id");
      state.priceType = action.payload.priceType;
    });
    builder.addCase(updateProfileAction.rejected, (state) => {});
  },
});

export const {
  changeCategories,
  changeRegions,
  logOut,
  resetFavoriteList,
  resetMyJobs,
  changeJobRegions,
  changeJobCategories,
} = userSlice.actions;
export default userSlice.reducer;
