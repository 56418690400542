import { React } from "react";
import { StyleSheet, SafeAreaView, View } from "react-native";
import { TabView, TabBar } from "react-native-tab-view";
import { useState } from "react";
import { useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import _ from "loadsh";
import { useCallback } from "react";
import JobList from "../components/JobList";
import UserList from "../components/UserList";
import SearchFilters from "./SearchFilters";
import FilterIcon from "../components/FilterIcon";
import CreateJobButtons from "../components/CreateJobButtons";
import SearchJobsInput from "../components/SearchJobsInput";
import BackButton from "../components/BackButton";
import { useUsersSearch, useJobSearch } from "../hooks";
import { useSelector } from "react-redux";

const TabType = {
  JOBS: "jobs",
  USERS: "users",
};
const renderTabBar = (props) => {
  return (
    <TabBar
      {...props}
      getLabelText={({ route }) => `${route.title} ${props[route.key]}`}
    />
  );
};

export default function JobsSearch({ navigation }) {
  const route = useRoute();
  const [showFilters, setShowFilter] = useState(false);
  const alias = decodeURI(route.params.alias);
  const limit = 5;

  const [filters, setFilters] = useState({
    priceType: [],
    taskType: [],
    sortBy: "createdAt",
    sortOrder: "asc",
  });

  const searchQuery = { limit, page: 1, alias, ...filters };
  const {
    data: usersData,
    fetchNextPage: fetchNextUsersPage,
    isRefetching: isUsersRefetching,
    isFetching: isUsersFetching,
  } = useUsersSearch(searchQuery);

  const {
    data: jobsData,
    fetchNextPage: fetchNextJobsPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching: isJobFetching,
    isRefetching: isJobsRefetching,
  } = useJobSearch(searchQuery);

  const users_list = _.get(usersData, "pages", [])
    .map((page) => page.list)
    .flat();
  const jobs_list = _.get(jobsData, "pages", [])
    .map((page) => page.list)
    .flat();

  const users_count = _.get(usersData, "pages[0].count", 0);
  const jobs_count = _.get(jobsData, "pages[0].count", 0);

  const categories = useSelector((state) => state.categories);

  const onApplyFilters = useCallback((f) => {
    setFilters(f);
    setShowFilter(false);
  }, []);

  const onJobsEndReached = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextJobsPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case TabType.USERS:
        return (
          <UserList
            isFetching={isUsersFetching}
            isRefetching={isUsersRefetching}
            list={users_list}
            onEndReached={fetchNextUsersPage}
          />
        );
      case TabType.JOBS:
        return (
          <JobList
            isFetching={isJobFetching}
            isRefetching={isJobsRefetching}
            data={jobs_list}
            onEndReached={onJobsEndReached}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (_.size(categories) > 0 && route.params.alias) {
      // const category = _.find(categories, { alias: decodeURI(route.params.alias) })
      // const categoryParent = _.find(categories, { parentId: category.id })
      // linkTo(`/podcasts/featured`)
      // navigation.setOptions({
      // 	title: "",
      // 	headerRight: () => (
      // 		<View
      // 			style={{
      // 				flexDirection: "row",
      // 				justifyContent: "space-between",
      // 				alignItems: "center",
      // 				width: "100%",
      // 			}}
      // 		>
      // 			<SearchJobsInput width={"80%"} marginRight={0} />
      // 			<FilterIcon
      // 				onPress={() => {
      // 					setShowFilter(true);
      // 				}}
      // 			/>
      // 		</View>
      // 	),
      // });

      navigation.setOptions({
        title: "",
        headerTitleStyle: {
          color: "blue",
          maxWidth: 1000,
        },
        headerBackVisible: false,
        headerLeft: () => <BackButton backLinkTo={"/"} />,
        headerTitle: () => (
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignSelf: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <SearchJobsInput />
          </View>
        ),
        headerStyle: {
          backgroundColor: "#209CFF",
          display: "flex",
          justifyContent: "center",
          width: 50,
        },
        headerRight: () => (
          <View style={{ width: 40 }}>
            <FilterIcon
              onPress={() => {
                setShowFilter(true);
              }}
            />
          </View>
        ),
      });
    }
  }, [navigation, categories, route.params.alias]);

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: TabType.USERS, title: "Виконавці" },
    { key: TabType.JOBS, title: "Оголошення" },
  ]);

  return (
    <SafeAreaView style={styles.container}>
      <SearchFilters
        initialValues={filters}
        visible={showFilters}
        onBack={() => {
          setShowFilter(false);
        }}
        onApplyFilters={onApplyFilters}
      />
      <TabView
        renderTabBar={(props) =>
          renderTabBar({
            ...props,
            users: users_count,
            jobs: jobs_count,
          })
        }
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{
          width: 400,
        }}
      />
      <CreateJobButtons />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingBottom: 65,
  },
});
