import { StyleSheet, View, Pressable, Text } from "react-native";
import Icon from "./../components/icons/CustomIcon";

export default function CustomMultiselect({
  items,
  onDelete,
  onListView,
  label,
  borderColor = "#979797",
}) {
  return (
    <View>
      {label && (
        <View>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      <View style={[styles.select_box, { borderColor: borderColor }]}>
        <View style={styles.item_box}>
          {items.map((item) => (
            <Pressable
              onPress={() => {
                onDelete(item.id);
              }}
              key={item.id}
            >
              <View style={styles.item}>
                <Text style={styles.item_text}>{item.name}</Text>
                <Text style={styles.item_close}>×</Text>
              </View>
            </Pressable>
          ))}
        </View>
        <Pressable onPress={onListView}>
          <View style={styles.down_icon}>
            <Icon
              name="chevron-left"
              size={17}
              color="#979797"
              style={{ transform: [{ rotate: "-90deg" }] }}
            />
          </View>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  select_box: {
    width: "100%",
    borderRadius: 30,
    minHeight: 48,
    flexDirection: "row",
    backgroundColor: "#ffffff",
    paddingLeft: 13,
    paddingTop: 10,
    borderWidth: 1,
    alignItems: "flex-start",
  },
  item_box: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginRight: 10,
    height: "100%",
    flex: 1,
  },
  down_icon: {
    flexShrink: 0,
    width: 47,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 6,
  },
  item: {
    backgroundColor: "#2599f8",
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "flex-start",
    marginRight: 5,
    marginBottom: 10,
  },
  item_text: {
    color: "#ffffff",
    marginRight: 5,
  },
  item_close: {
    color: "#ffffff",
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: "bold",
    marginBottom: 10,
    marginLeft: 10,
    color: "#254D5C",
  },
});
