import { Modal } from "react-native";
import { SafeAreaView } from "react-native";
import _ from "loadsh";
import VerificationCode from "../components/VerificationCode";
import Header from "../components/Header";

export default function ResetPasswordCodeModal({
  error = null,
  visible = false,
  phone,
  onClose = () => {},
  onVerify = () => {},
}) {
  return (
    <Modal animationType={"slide"} transparent={false} visible={visible}>
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <Header onBackPress={onClose} title={"Верифікація коду"} />
        <VerificationCode
          phone={`+380${phone}`}
          error={error}
          onVerify={onVerify}
        />
      </SafeAreaView>
    </Modal>
  );
}
