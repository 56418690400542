import { StyleSheet, View, TextInput, TouchableOpacity } from "react-native";
import Icon from "./../components/icons/CustomIcon";
import { useLinkTo } from "@react-navigation/native";

export default function SearchInput({
  value,
  onChangeText,
  onBlur,
  placeholder,
  style = {},
  onSubmitEditing,
  onSearchPress = () => {},
}) {
  return (
    <View style={[styles.search_box, style]}>
      <TextInput
        style={styles.input}
        value={value}
        onChangeText={onChangeText}
        onBlur={onBlur}
        onSubmitEditing={onSubmitEditing}
        placeholder={placeholder}
        underlineColorAndroid="transparent"
        placeholderTextColor="#C4C4C4"
      />
      <TouchableOpacity style={styles.search_icon} onPress={onSearchPress}>
        <Icon name="search" size={17} color="#868686" />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  search_box: {
    width: "100%",
    borderRadius: 30,
    height: 32,
    flexDirection: "row",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    paddingLeft: 13,
    paddingVertical: 7,
    borderWidth: 1,
    borderColor: "#209CFF",
    alignItems: "center",
  },
  input: {
    height: 32,
    flexGrow: 1,
  },
  search_icon: {
    flexShrink: 0,
    width: 47,
    alignItems: "center",
    justifyContent: "center",
  },
});
