import { StyleSheet, Image, View, TouchableOpacity, Text } from "react-native";
import Icon from "./icons/CustomIcon";

export default function CategoryItem({
  image,
  name,
  onPress,
  sub = true,
  jobsCount = 0,
}) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.flex_row, styles.item_box]}>
        <View style={[styles.flex_row, styles.left_box]}>
          <Image style={styles.logo} source={{ uri: image }} />
          <Text style={styles.item_text}>{name}</Text>
        </View>
        {jobsCount ? (
          <Text style={styles.item_count}>{`${
            jobsCount === 0 ? "0" : jobsCount
          }`}</Text>
        ) : null}
        {sub && <Icon name="chevron-rightsvg" color="#333333" size={16} />}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  item_box: {
    justifyContent: "space-between",
    minHeight: 60,
    paddingVertical: 10,
    paddingLeft: 10,
    paddingRight: 18,
    borderBottomColor: "#BCBBC1",
    borderBottomWidth: 1,
  },
  left_box: {
    flexShrink: 1,
    marginRight: 10,
  },
  item_text: {
    fontSize: 18,
    lineHeight: 22,
    color: "#4C4C4C",
    flexShrink: 1,
    flexWrap: "wrap",
  },
  logo: {
    width: 30,
    height: 30,
    marginRight: 15,
  },
  item_count: {
    color: "#BDBDBD",
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 10,
  },
});
