import { StyleSheet, View, Text, ScrollView } from "react-native";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import Logo from "../components/Logo";
import { Formik } from "formik";
import * as yup from "yup";
import { useToast } from "react-native-toast-notifications";
import SignUpCodeModal from "./SignUpCodeModal";
import { useCallback, useState, useRef } from "react";
import { verifySignInCodeApi, signUpApi } from "../api/users.api";
import PhoneInput from "../components/PhoneInput";

const schema = yup.object().shape({
  phone: yup
    .string()
    .min(9, "Телефон є обовʼязковим")
    .required("Телефон є обовʼязковим"),
  password: yup.string().required("Пароль є обовʼязковим"),
  passwordConfirmation: yup
    .string()
    .required("Підтвердження паролю є обовʼязковим")
    .oneOf([yup.ref("password"), null], "Паролі повинні бути однаковими"),
});

export default function SignUp({ navigation }) {
  navigation.setOptions({ title: 'Реєстрація', headerTintColor: 'white'})  
  const toast = useToast();
  const formik = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [verCodeError, setVerCodeError] = useState(null);

  const onVerify = useCallback(
    async (code) => {
      try {
        await verifySignInCodeApi({
          phone: formik?.current?.values?.phone,
          code,
        });
        setShowModal(false);
        toast.show("Ваш номер телефону підтверджено.Ви можете увійти зараз.", {
          placement: "top",
          type: "success",
        });
        navigation.reset({ routes: [{ name: "SignIn" }] });
      } catch (e) {
        setVerCodeError(e.response.data.message);
      }
    },
    [formik, toast]
  );

  const onSubmit = useCallback(
    async (values) => {
      const phone = `+380${values.phone}`;
      const password = values.password;
      try {
        await signUpApi({ password, phone });
        setShowModal(true);
      } catch (e) {
        toast.show(e.response.data.message, {
          placement: "top",
          type: "danger",
        });
      }
    },
    [toast, formik]
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={styles.container}>
        <View style={styles.logo_box}>
          <Logo height={55} />
        </View>
        <Text style={styles.form_title}>Зареєструватися</Text>
        <Formik
          innerRef={formik}
          initialValues={{
            phone: "+38(0",
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            values,
            errors,
            touched,
          }) => (
            <View style={styles.form}>
              <View style={styles.form_control}>
                <PhoneInput
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={values.phone}
                  error={errors.phone && touched.phone ? errors.phone : null}
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Пароль"
                  onChangeText={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  error={
                    errors.password && touched.password ? errors.password : null
                  }
                  secureTextEntry
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  placeholder="Підтвердити пароль"
                  onChangeText={handleChange("passwordConfirmation")}
                  onBlur={handleBlur("passwordConfirmation")}
                  value={values.passwordConfirmation}
                  error={
                    errors.passwordConfirmation && touched.passwordConfirmation
                      ? errors.passwordConfirmation
                      : null
                  }
                  secureTextEntry
                />
              </View>
              <CustomButton
                full
                text="Зареєструватися"
                onPress={handleSubmit}
                disabled={!(isValid && dirty)}
              />
            </View>
          )}
        </Formik>
      </View>
      <SignUpCodeModal
        visible={showModal}
        error={verCodeError}
        onVerify={onVerify}
        phone={formik?.current?.values?.phone}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#ffffff",
  },
  logo_box: {
    width: "100%",
    marginBottom: 95,
    paddingHorizontal: 5,
  },
  form_title: {
    fontSize: 28,
    lineHeight: 35,
    color: "#000000",
    marginBottom: 30,
  },
  form: {
    width: "100%",
  },
  form_control: {
    marginBottom: 30,
  },
});
