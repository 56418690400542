import { Platform } from "react-native";
import { useCallback, useEffect, useState } from "react";
import { fetchJobForEdit, updateJob } from "../../store/user/index";
import JobFormBase from "../JobFormBase";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "react-native-toast-notifications";
import { addJobGaleryApi, deleteJobGaleryApi } from "../../api/jobs.api";
import { range } from 'loadsh';

const EditJob = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const initialValues = useSelector((state) => state.user.editJob);
  const [gallery, setGallery] = useState(range(6).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true})));

  useEffect(() => {
    dispatch(fetchJobForEdit(route.params.id));
  }, [route.params]);

  useEffect(() => {
    const _l = initialValues.gallery.length;
    const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
    const new_gallery = initialValues.gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
    setGallery([...new_gallery, ...disabled_items])
  }, [initialValues]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(updateJob(values));
        toast.show("Оголошення було успішно відредаговано", {
          placement: "top",
          type: "success",
        });
        navigation.reset({ index: 0, routes: [{ name: "MyJobsPage" }] });
      } catch (e) {}
    },
    [toast]
  );

  const setImageLoading = useCallback(
    (index, state) => {
      setGallery((p) => {
        return p.map((v, i) => {
          return { ...v, loading: index === i ? state : false };
        });
      });
    },
    [gallery]
  );

  const onGalleryDelete = useCallback(
    async (id) => {
      // setImageLoading(index, true);
      await deleteJobGaleryApi(id, initialValues.id);
      const filtered_gallery = gallery.filter(i  => i.id && i.id !== id)
      const _l = filtered_gallery.length;
      const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
      const new_gallery = filtered_gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
      setGallery([...new_gallery, ...disabled_items])
    },
    [gallery, initialValues]
  );

  const onOrderChange = useCallback((files) => {
    setGallery(files);
  },[gallery])

  const onAddGallery = useCallback(
    async (image) => {
      const prev_state = gallery;

      const formData = new FormData();
      if (Platform.OS === "web") {
        formData.append("image", image.file);
      } else {
        formData.append("image", { ...image, type: image.mimeType });
      }
      const { data } = await addJobGaleryApi(initialValues.id, formData);
      const filtered_gallery = [...gallery.filter(i  => i.id),data]
      const _l = filtered_gallery.length;
      const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
      const new_gallery = filtered_gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
      setGallery([...new_gallery, ...disabled_items])
    },
    [gallery, initialValues]
  );

  return (
    <JobFormBase
      initialValues={{
        ...initialValues,
        executionDate: new Date(initialValues.executionDate),
      }}
      submitButtonText="Редагувати"
      mode={"edit"}
      images={gallery.map((f) => ({ ...f, uri: f.fileName }))}
      onAdd={onAddGallery}
      onDelete={onGalleryDelete}
      onOrderChange={onOrderChange}
      onSubmit={onSubmit}
    />
  );
};

export default EditJob;
