import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { feedbackApi, leaveFeedbackApi } from "../../api/feedback.api";

export const fetchFeedbacks = createAsyncThunk(
  "jobs/fetchFeedbacks",
  async (options) => {
    const { data } = await feedbackApi(options);
    return data;
  }
);

const feedbackSlice = createSlice({
  name: "feedbacks",
  initialState: { page: 1, count: 1, limit: 0, list: [] },
  reducers: {
    resetFeedbacks: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeedbacks.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.page = action.payload.page;
      state.count = action.payload.count;
      state.pages = action.payload.pages;
    });
  },
});

export const { resetFeedbacks } = feedbackSlice.actions;

export default feedbackSlice.reducer;
