import { StyleSheet, View, Text, ScrollView } from "react-native";
import CustomButton from "../components/CustomButton";
import Logo from "../components/Logo";
import { Formik } from "formik";
import * as yup from "yup";
import {
  resetPasswordRequestApi,
  verifyResetPasswordCodeApi,
} from "../api/users.api";
import ResetPasswordCodeModal from "./ResetPasswordCodeModal";
import { useCallback, useRef, useState, useLayoutEffect } from "react";
import PhoneInput from "../components/PhoneInput";

const schema = yup.object().shape({
  phone: yup
    .string()
    .min(9, "Телефон є обовʼязковим")
    .required("Телефон є обовʼязковим"),
});

export default function ForgotPassword({ navigation, route }) {
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [verCodeError, setVerCodeError] = useState(null);
  const formik = useRef(null);

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Відновлення паролю', headerTintColor: 'white'})
  },[])

  const onVerify = useCallback(
    async (verificationCode) => {
      try {
        const {
          data: { token },
        } = await verifyResetPasswordCodeApi({
          phone: `+380${formik?.current?.values?.phone}`,
          verificationCode,
        });
        setShowModal(false);
        navigation.push("ResetPassword", { token });
      } catch (e) {
        setVerCodeError(e.response.data.message);
      }
    },
    [formik]
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={styles.container}>
        <View style={styles.logo_box}>
          <Logo height={55} />
        </View>
        <Text style={styles.form_title}>
          {" "}
          Введіть номер телефону, на який зареєстровано аккаунт:
        </Text>
        {error && (
          <Text style={{ marginBottom: 10, color: "red" }}>{error}</Text>
        )}
        <Formik
          innerRef={formik}
          initialValues={{ phone: "+38(0", password: "" }}
          validationSchema={schema}
          onSubmit={async ({ phone }) => {
            try {
              const updatedPhone = `+380${phone}`;
              await resetPasswordRequestApi(updatedPhone);
              setShowModal(true);
            } catch (e) {
              setError(e.response.data.message);
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            values,
            errors,
            touched,
          }) => (
            <View style={styles.form}>
              <View style={styles.form_control}>
                <PhoneInput
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={values.phone}
                  error={errors.phone && touched.phone ? errors.phone : null}
                />
              </View>
              <View style={styles.btn_box}>
                <CustomButton
                  full
                  text="Відновити пароль"
                  onPress={handleSubmit}
                  disabled={!(isValid && dirty)}
                />
              </View>
            </View>
          )}
        </Formik>
      </View>
      <ResetPasswordCodeModal
        visible={showModal}
        error={verCodeError}
        onVerify={onVerify}
        phone={formik?.current?.values?.phone}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    backgroundColor: "#ffffff",
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  logo_box: {
    width: "100%",
    marginBottom: 95,
    paddingHorizontal: 5,
  },
  form_title: {
    fontSize: 28,
    lineHeight: 35,
    color: "#000000",
    marginBottom: 30,
    textAlign: "center",
  },
  pass_link: {
    fontSize: 16,
    lineHeight: 22,
    color: "#000000",
    textAlign: "center",
    textDecorationLine: "underline",
    marginBottom: 30,
    marginTop: 14,
  },
  btn_box: {
    width: "100%",
    // maxWidth: 305
  },
  form: {
    width: "100%",
    // maxWidth: 305
  },
  form_control: {
    marginBottom: 30,
  },
});
