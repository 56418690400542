import { Text, Modal, Pressable } from "react-native";
import { View, StyleSheet, TextInput, SafeAreaView } from "react-native";
import Checkbox from "expo-checkbox";
import CustomButton from "../components/CustomButton";
import { Formik } from "formik";
import { useCallback, useRef, useState } from "react";
import _ from "loadsh";
import { Header } from "@react-navigation/elements";
import Icon from "../components/icons/CustomIcon";
import SelectedListItem from "../components/SelectedListItem";
const TaskTypes = {
  FIND: "find",
  OFFER: "offer",
};

const PriceTypes = {
  HOURLY: "hourly",
  FIXED: "fixed",
};

const commonTitleStyle = {
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

const sortOptionsList = [
  {
    label: "дата↓",
    value: "createdAtAsc",
    sortBy: "createdAt",
    sortOrder: "asc",
  },
  {
    label: "дата↑",
    value: "createdAtDesc",
    sortBy: "createdAt",
    sortOrder: "desc",
  },
  {
    label: "За зменшенням ціни",
    value: "priceAsc",
    sortBy: "price",
    sortOrder: "asc",
  },
  {
    label: "За зростанням ціни",
    value: "priceDesc",
    sortBy: "price",
    sortOrder: "desc",
  },
];

const SortByModal = ({ visible = false, value, onValueChange, onBack }) => {
  return (
    <Modal animationType={"slide"} transparent={false} visible={visible}>
      <SafeAreaView style={{ backgroundColor: "blue" }}>
        <Header
          title="Сортувати"
          {...commonTitleStyle}
          layout={{ width: 500 }}
          headerLeft={() => (
            <Pressable
              onPress={() => {
                onBack();
              }}
              style={{ padding: 10 }}
            >
              <Icon name="chevron-left" color="white" size={16} />
            </Pressable>
          )}
          modal={true}
        ></Header>
        <View style={styles.container}>
          {sortOptionsList.map((o) => (
            <SelectedListItem
              name={o.label}
              selected={value === o}
              checkbox={true}
              onPress={() => {
                onValueChange(o);
              }}
            />
          ))}
        </View>
      </SafeAreaView>
    </Modal>
  );
};

export default function SearchFilters({
  visible = false,
  onBack = () => {},
  onApplyFilters,
  initialValues = {
    priceType: [],
    taskType: [],
    sortBy: "createdAt",
    sortOrder: "asc",
    priceMax: 0,
    priceMin: 0,
  },
}) {
  const formik = useRef(null);
  const [showSortModal, setShowSortModal] = useState(false);

  const onCheckBoxChange = useCallback(
    (propName, type) => {
      const list = formik.current.values[propName];
      if (list.includes(type)) {
        formik.current.setFieldValue(
          propName,
          list.filter((v) => v !== type)
        );
      } else {
        formik.current.setFieldValue(propName, [...list, type]);
      }
    },
    [formik]
  );

  return (
    <Modal
      animationType={"slide"}
      transparent={false}
      visible={visible}
      onRequestClose={() => {
        Alert.alert("Modal has now been closed.");
      }}
    >
      <SafeAreaView style={{ backgroundColor: "blue" }}>
        <Header
          title={<View style={{ width: 100, height: 100 }}></View>}
          {...commonTitleStyle}
          layout={{ width: 500 }}
          headerLeft={() => (
            <Pressable
              onPress={() => {
                onBack();
              }}
              style={{ padding: 10 }}
            >
              <Icon name="chevron-left" color="white" size={16} />
            </Pressable>
          )}
          modal={true}
        ></Header>

        <Formik
          innerRef={formik}
          onSubmit={(values) => {
            onApplyFilters(_.omitBy(values, _.isNil));
          }}
          initialValues={initialValues}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <View style={styles.container}>
              <View style={styles.row}>
                <Text>Мінімальна ціна</Text>
                <TextInput
                  style={styles.input}
                  value={String(values.priceMin || "")}
                  onChangeText={(v) => {
                    setFieldValue("priceMin", Number(v));
                  }}
                ></TextInput>
              </View>

              <View style={styles.row}>
                <Text>Максимальна ціна</Text>
                <TextInput
                  style={styles.input}
                  value={String(values.priceMax || "")}
                  onChangeText={(v) => {
                    setFieldValue("priceMax", Number(v));
                  }}
                ></TextInput>
              </View>

              <View style={[styles.row, styles.topBorder]}>
                <Text style={styles.greyLabel}>
                  Показати з погодинною оплатою
                </Text>
                <Checkbox
                  value={values.priceType.includes("hourly")}
                  onValueChange={(v) => {
                    onCheckBoxChange("priceType", PriceTypes.HOURLY);
                  }}
                ></Checkbox>
              </View>
              <View style={styles.row}>
                <Text style={styles.greyLabel}>
                  Показати з фіксованою оплатою
                </Text>
                <Checkbox
                  value={values.priceType.includes("fixed")}
                  onValueChange={(v) => {
                    onCheckBoxChange("priceType", PriceTypes.FIXED);
                  }}
                ></Checkbox>
              </View>
              <View style={[styles.row, styles.topBorder]}>
                <Text style={styles.greyLabel}>Показати “Пропоную роботу”</Text>
                <Checkbox
                  value={values.taskType.includes(TaskTypes.OFFER)}
                  onValueChange={(v) => {
                    onCheckBoxChange("taskType", TaskTypes.OFFER);
                  }}
                ></Checkbox>
              </View>
              <View style={styles.row}>
                <Text style={styles.greyLabel}>Показати “Шукаю роботу”</Text>
                <Checkbox
                  value={values.taskType.includes(TaskTypes.FIND)}
                  onValueChange={(v) => {
                    onCheckBoxChange("taskType", TaskTypes.FIND);
                  }}
                ></Checkbox>
              </View>
              <View>
                <View style={[styles.row, styles.topBorder]}>
                  <Text>
                    Сортувати за:{" "}
                    {
                      sortOptionsList.find(
                        (d) =>
                          d.sortBy === values.sortBy &&
                          d.sortOrder === values.sortOrder
                      ).label
                    }
                  </Text>
                  <View style={{ width: 100 }}>
                    <CustomButton
                      text={"Змінити"}
                      full
                      size={"xs"}
                      onPress={() => {
                        setShowSortModal(true);
                      }}
                    ></CustomButton>
                  </View>
                </View>
                <View style={{ marginTop: 30 }}>
                  <CustomButton
                    text="Застосувати фільтри"
                    full
                    onPress={handleSubmit}
                  />

                  <SortByModal
                    visible={showSortModal}
                    onBack={() => {
                      setShowSortModal(false);
                    }}
                    onValueChange={(value) => {
                      const { sortBy, sortOrder } = value;
                      setFieldValue("sortBy", sortBy);
                      setFieldValue("sortOrder", sortOrder);
                      setShowSortModal(false);
                    }}
                    value={sortOptionsList.find(
                      (d) =>
                        d.sortBy === values.sortBy &&
                        d.sortOrder === values.sortOrder
                    )}
                  />
                </View>
              </View>
            </View>
          )}
        </Formik>
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  greyLabel: { color: "#767676" },
  container: {
    paddingHorizontal: 20,
    backgroundColor: "white",
  },
  topBorder: {
    borderTopColor: "#E5E5E5",
    borderTopWidth: 1,
  },
  row: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
  },
  input: {
    width: 111,
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    lineHeight: 22,
    height: 37,
    borderWidth: 1,
    borderRadius: 30,
    fontWeight: "600",
    color: "#4CD964",
  },
});
