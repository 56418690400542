import { createNativeStackNavigator } from "@react-navigation/native-stack";
import BlogsPage from '../pages/BlogsPage';
import OpenDrawerButton from "../components/OpenDrawerButton";

const Stack = createNativeStackNavigator();

const commonOptions = {
  headerStyle: { backgroundColor: "#209CFF" },
};

const commonDrawerPageOptions = {
  headerLeft: () => <OpenDrawerButton />,
  headerStyle: { backgroundColor: "#209CFF" },
  headerTitleStyle: {
    color: "white",
  },
};

export default function BlogStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="BlogsPage"
        component={BlogsPage}
        options={{ ...commonDrawerPageOptions, title: "Статті" }}
      />
    </Stack.Navigator>
  );
}
