import { Modal, View, Text, StyleSheet } from "react-native";
import CustomButton from "../CustomButton";

export default function DeleteAccountModal({
  visible = false,
  onRemoveAccount = () => {},
  onCancel = () => {},
}) {

  return (
    <Modal
      transparent={true}
      visible={visible}
      style={{ position: "relative" }}
    >
      <View
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#ffffff7a",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.title}>Ви дійсно бажаєте видалити обліковий запис?</Text>
          <View style={styles.buttonsContainer}>
            <View style={styles.button}>
              <CustomButton
                onPress={onRemoveAccount}
                type="blue"
                text="Підтвердити"
                size="sm"
                full
              />
            </View>
            <View style={styles.button}>
              <CustomButton
                onPress={onCancel}
                type="red"
                text="Скасувати"
                size="sm"
                full
              />
            </View>
          </View>
        </View>
      </View>
      <View></View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
  buttonsContainer: {
    marginRight: 10,
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
  },
  modalContainer: {
    maxWidth: 400,
    width: "80%",
    borderWidth: 1,
    borderRadius: 8,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    borderColor: "rgb(224, 224, 224)",
    backgroundColor: "white",
  },
  title: {
    fontSize: 17,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
  },
});
