import React from "react-native";
import { Modal, View, FlatList } from "react-native";
import { SafeAreaView } from "react-native";
import { useCallback } from "react";
import Header from "../Header";
import { useFeedbacks } from "../../hooks/index";
import ReviewItem from "../ReviewItem";
// import FeedbackItemSkeleton from "../Skeletons/FeedbackItemSkeleton";

// const FeedbacksSkeletoStack = () => (
// 	<>
// 		<FeedbackItemSkeleton />
// 		<FeedbackItemSkeleton />
// 		<FeedbackItemSkeleton />
// 	</>
// );

export default function FeedbacksModal({
  visible = false,
  id,
  onClose = () => {},
}) {
  const {
    data,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching,
    hasNextPage,
  } = useFeedbacks({ limit: 4, page: 1, userId: id });

  const onEndReached = useCallback(() => {
    if (hasNextPage && !isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage, hasNextPage]);

  return (
    <Modal animationType={"slide"} transparent={false} visible={visible}>
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <Header onBackPress={onClose} title={"Відгуки"} />
        <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
          <FlatList
            // 			ListEmptyComponent={
            //   isFetching || isRefetching ? <FeedbacksSkeletoStack /> : <></>
            // 			}
            data={data}
            extraData={data}
            renderItem={({ item, index }) => (
              <ReviewItem {...item} key={index} />
            )}
            keyExtractor={(item, index) => index}
            onEndReachedThreshold={0.4}
            onEndReached={onEndReached}
            // ListFooterComponent={isFetching ? <FeedbacksSkeletoStack /> : <></>}
          />
        </View>
      </SafeAreaView>
    </Modal>
  );
}
