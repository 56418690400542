import { View, FlatList, Text } from "react-native";
import UserListItem from "../components/UserListItem";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { apiSelector } from "../store/api/selector";
import _ from "loadsh";
import { useJobSearch, useToggleFavorite } from "../hooks";
import JobItemSkeleton from "./Skeletons/JobItemSkeleton";
import { useCallback } from "react";

const NoContentHeader = () => {
  return (
    <View
      style={{
        display: "flex",
        padding: 30,
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "600" }}>
        Поки немае оголошення, стань першим{" "}
      </Text>
    </View>
  );
};

const JobList = (props) => {
  const navigation = useNavigation();
  const { onFavorite, isFavorite } = useToggleFavorite();
  const { data, onEndReached, isFetching, isRefetching } = props;

  return (
    <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
      <FlatList
        ListEmptyComponent={
          isFetching || isRefetching ? (
            <>
              <JobItemSkeleton />
              <JobItemSkeleton />
              <JobItemSkeleton />
            </>
          ) : (
            <NoContentHeader />
          )
        }
        data={data}
        extraData={data}
        renderItem={({ item }) => (
          <UserListItem
            {...item}
            key={item.id}
            onFavorite={() => onFavorite(item.id)}
            favorite={isFavorite(item.id)}
            onPress={() => {
              navigation.push("JobDetails", { alias: item.alias });
            }}
          />
        )}
        keyExtractor={(item, index) => index}
        onEndReachedThreshold={0.8}
        onEndReached={onEndReached}
        ListFooterComponent={
          isFetching ? (
            <>
              <JobItemSkeleton />
              <JobItemSkeleton />
              <JobItemSkeleton />
            </>
          ) : (
            <></>
          )
        }
      />
    </View>
  );
};

export default JobList;
