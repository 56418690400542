import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { useState } from "react";
import Checkbox from "expo-checkbox";

export default function SelectedListItem({
  name,
  onPress,
  checkbox = true,
  selected = false,
  onChange,
}) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.item_box}>
        <View style={[styles.flex_row, styles.left_box]}>
          {checkbox && (
            <View style={{ paddingTop: 5, marginRight: 10 }}>
              <Checkbox value={selected} />
            </View>
          )}
          <Text style={styles.item_text}>{name}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  flex_row: {
    flexDirection: "row",
    alignItems: "center",
  },
  item_box: {
    minHeight: 44,
    paddingVertical: 10,
    paddingLeft: 10,
    paddingRight: 18,
    borderBottomColor: "#BCBBC1",
    borderBottomWidth: 1,
  },
  left_box: {
    flexShrink: 1,
  },
  item_text: {
    fontSize: 17,
    lineHeight: 22,
    color: "#4C4C4C",
    flexShrink: 1,
    flexWrap: "wrap",
  },
});
