import { View, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";

const SlideButton = ({ icon, onPress, style = {} }) => {
  return (
    <Pressable onPress={onPress} style={style}>
      <View
        style={{
          position: "absolute",
          alignSelf: "center",
          bottom: 3,
          width: 32,
          height: 40,
          backgroundColor: "#209CFF",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
        }}
      >
        <Icon name={icon} color="#FFFFFF" size={9} />
      </View>
    </Pressable>
  );
};

export default SlideButton;
