import { Platform } from "react-native";
import { createJobApi } from "../api/jobs.api";
import JobFormBase from "./JobFormBase";
import { useToast } from "react-native-toast-notifications";
import { useCallback, useState } from "react";
import { compact, range } from "loadsh";
import { date } from "yup";

const CreateJob = ({ navigation, route }) => {
  const toast = useToast();
  const [gallery, setGallery] = useState(range(6).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true})));


  const onSubmit = useCallback(
    (job) => {
      const formData = new FormData();
      const { ...rest } = job;
      formData.append("userId", job.userId);
      formData.append("price", job.price);
      formData.append("title", job.title);
      formData.append("executionDate", job.executionDate);
      formData.append("description", job.description);
      formData.append("taskType", job.taskType);
      formData.append("priceType", job.priceType);
      rest.categories.forEach((cat) => {
        formData.append("categories[]", cat);
      });

      rest.regions.forEach((reg) => {
        formData.append("regions[]", reg);
      });

      job.gallery.forEach((image) => {
        if (Platform.OS === "web") {
          formData.append("gallery[]", image.file);
        } else {
          formData.append("gallery[]", { ...image, type: image.mimeType });
        }
      });

      createJobApi(formData)
        .then(() => {})
        .finally(() => {
          toast.show("Нове Оголошення було успішно створено", {
            placement: "top",
            type: "success",
          });
          navigation.reset({ index: 0, routes: [{ name: "MyJobsPage" }] });
        });
    },
    [toast, gallery]
  );

  const onGalleryDelete = useCallback(
    async (id) => {
      const filtered_gallery = gallery.filter(i  => i.id && i.id !== id)
      const _l = filtered_gallery.length;
      const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
      const new_gallery = filtered_gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
      setGallery([...new_gallery, ...disabled_items])
    },
    [gallery]
  );

  const onAddGallery = useCallback(
    (file) => {
      const filtered_gallery = [...gallery.filter(i  => i.id),{...file, id: Math.random().toString(16).slice(2)}]
      const _l = filtered_gallery.length;
      const disabled_items = range(6 - _l).map((item,index) => ({key: Math.random().toString(16).slice(2), disabledDrag: true, disabledReSorted: true}))
      const new_gallery = filtered_gallery.map(v => ({...v, key: Math.random().toString(16).slice(2)}))
      setGallery([...new_gallery, ...disabled_items])
    },
    [gallery, setGallery]
  );

  const onOrderChange = useCallback((files) => {
    console.log('files order',files)
    setGallery(files);

  },[gallery])

  return (
    <JobFormBase
      onSubmit={onSubmit}
      images={gallery}
      onAdd={onAddGallery}
      onOrderChange={onOrderChange}
      onDelete={onGalleryDelete}
    />
  );
};

export default CreateJob;
