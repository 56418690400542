import { configureStore } from "@reduxjs/toolkit";
import categoriesReducer from "./categories";
import jobsReducer from "./jobs";
import usersReducer from "./users";
import regionsReducer from "./regions";
import apiReducer from "./api/slice";
import userReducer from "./user";
import Interceptor from "../api/config";
import feedbackSlice from "./feedbacks";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

const persistConfig = {
  key: "user",
  storage: AsyncStorage,
};

const persisterReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    jobs: jobsReducer,
    users: usersReducer,
    regions: regionsReducer,
    user: persisterReducer,
    api: apiReducer,
    feedbacks: feedbackSlice,
  },
  middleware: [
    thunk,
    // logger
  ],
});

export default store;
export const persistor = persistStore(store);

Interceptor.interceptor(store);
