const axios = require("axios").default;
var qs = require("qs");

export const feedbackApi = (params = {}) => {
  return axios.get(`/feedback?${qs.stringify(params)}`).then((r) => r.data);
};

export const leaveFeedbackApi = (body = {}) => {
  return axios.post(`/feedback`, body);
};
