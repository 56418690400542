import { StyleSheet, View, SafeAreaView, Modal } from "react-native";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import { Formik } from "formik";
import * as yup from "yup";
import RatingInput from "../RatingInput";
import Header from "../Header";

const schema = yup.object().shape({
  comment: yup.string().required("Field is required"),
});

export default function LeaveFeedbackModal({
  onSubmit = () => {},
  visible,
  onClose = () => {},
}) {
  return (
    <Modal
      animationType={"slide"}
      transparent={true}
      style={{ backgroundColor: "red" }}
      visible={visible}
    >
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <Header onBackPress={onClose} title={"Розмістити відгук"} />
        <View style={styles.container}>
          <Formik
            initialValues={{
              politeness: 1,
              punctuality: 1,
              quality: 1,
              comment: "",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <View style={styles.form}>
                <View style={styles.form_control}>
                  <RatingInput
                    label="Якість виконаної роботи"
                    onPress={(r) => {
                      setFieldValue("quality", r);
                    }}
                  ></RatingInput>
                </View>

                <View style={styles.form_control}>
                  <RatingInput
                    label="Пунктуальність"
                    onPress={(r) => {
                      setFieldValue("punctuality", r);
                    }}
                  ></RatingInput>
                </View>

                <View style={styles.form_control}>
                  <RatingInput
                    label="Вічливість"
                    onPress={(r) => {
                      setFieldValue("politeness", r);
                    }}
                  ></RatingInput>
                </View>

                <View style={styles.form_control}>
                  <CustomInput
                    label="Відгук"
                    placeholder="Опишіть, які саме послуги замовляли"
                    onChangeText={handleChange("comment")}
                    onBlur={handleBlur("comment")}
                    value={values.comment}
                    error={
                      errors.comment && touched.comment ? errors.comment : null
                    }
                    multiline
                    numberOfLines={8}
                    borderColor="#209CFF"
                  />
                </View>

                <View style={styles.btn_box}>
                  <CustomButton
                    text="Розмістити відгук"
                    full
                    onPress={handleSubmit}
                  />
                </View>
              </View>
            )}
          </Formik>
        </View>
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 15,
    paddingBottom: 40,
    paddingTop: 20,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    backgroundColor: "#fff",
  },
  btn_box: {
    alignSelf: "center",
    width: "100%",
    marginTop: 25,
  },
  form: {
    width: "100%",
  },
  form_control: {
    marginBottom: 20,
  },
});
